import React, { useEffect, useState } from "react";
import { Link, NavLink } from "react-router-dom";
import "./home.css";
import BlogList from "./Layout/CitiesListSlider/BlogList";
import Faq from "./Layout/CitiesListSlider/Faq";
import HomeBanner from "./Layout/HomeBanner";
import KnowUs from "./Layout/KnowUs";
import Features from "./Layout/Features";
import DocumentMeta from "react-document-meta";
import Booking from "./Layout/Booking";
import AreaGround from "./Layout/AreaGrounf";
import SponseredVenues from "./Layout/SponseredVenues";
import DiverseAreas from "./Layout/DiverseAreas";

function Home() {
  const currentURL = window.location.href;
  const meta = {
    title: "Online Ground Booking in Lahore | Indoor & Outdoor Grounds",
    description:
      "Your favorite ground booking in Lahore is just one click away with Meidan. You can play indoor and outdoor games effortlessly by eliminating the call barrier.",
    canonical: currentURL,
    meta: {
      charset: "utf-8",
      name: {
        keywords:
          "Online Ground booking in Lahore, Indoor and outdoor games, playgrounds booking in Lahore, Find playgrounds in Lahore, Book playgrounds in Lahore, sports grounds near me, book sports grounds, sports grounds, playgrounds",
      },
    },
  };

  return (
    <DocumentMeta {...meta}>
      <HomeBanner />
      <SponseredVenues />
      <DiverseAreas />
      <KnowUs />
      <Features />
      <AreaGround />
      <BlogList />
      <Faq />
      <Booking />
    </DocumentMeta>
  );
}

export default Home;
