import { Link } from "react-router-dom";
import React, { Component, useEffect, useState } from "react";
import { getBlog } from "../../../../api/Blog/Blog";
import DocumentMeta from "react-document-meta";
const BlogList = () => {
  const [getBlogs, setGetBlogs] = useState([]);
  const [currentPage, setCurrentPage] = useState(1); // Track current page
  const [totalPages, setTotalPages] = useState(1);
  useEffect(() => {
    getBlogListing(currentPage);
  }, [currentPage]);

  const getBlogListing = async (page) => {
    try {
      const response = await getBlog(page);
      const data = response.data;
      let array = [...data.data];
      const uniqueArray = array.filter(
        (item, index, self) => index === self.findIndex((t) => t.id === item.id)
      );
      setGetBlogs(uniqueArray);
      setTotalPages(data.total_page);
    } catch (error) {}
  };
  return (
    <section>
      <div className="container">
        <div className="row justify-content-md-center text-center ">
          <div className="col blog-text mb-2">
            <h1>Blogs</h1>
          </div>
        </div>{" "}
        {/* <div className="row">
            <div className="col-lg-12">
              <h1 style={{ fontSize: "40px" }} className="text-center pb-4">
                Sports News Pakistan
              </h1>
              <p className="text-center">
                Stay up to date with the latest Sports News Pakistan with the
                Meidan Blog! Our blogs are full of exciting and engaging stories
                from the sports news Pakistan. The Meidan Blog brings you a
                diverse range of content that provides to your love for sports
                news Pakistan, ensuring your knowledge about the exciting
                developments in sports. Don't miss out on the action—stay
                connected with us for compelling articles that are a complete
                reflection of sports news Pakistan.
              </p>
            </div>
          </div> */}
        <div className="row">
          {getBlogs.length > 0 ? (
            getBlogs.slice(0, 3).map((items) => (
              <div className="col-sm-12 col-lg-4">
                <Link
                  className="homeBlog card p-3 blog_card"
                  to={`/blog-detail/${items.slug}`}
                >
                  <img src={`https://blogs.meidan.pk${items.display}`} alt="" />
                  <div className="card-body px-0">
                    <h5 class="card-title">{items.title}</h5>
                    <p class="card-text">
                      {items.parse_body.length > 300
                        ? `${items.parse_body.slice(0, 250)}...`
                        : items.parse_body}
                      &nbsp;
                      <Link
                        className="view-more"
                        to={`/blog-detail/${items.slug}`}
                      >
                        Read More
                      </Link>
                    </p>
                  </div>
                </Link>
              </div>
            ))
          ) : (
            <>
              {Array.from({ length: 3 }).map((_, index) => (
                <div className="col-sm-12 col-md-6 col-lg-4 mb-4">
                  <div className="shimmer">
                    <div>
                      <label className="line"></label>
                      <h5 style={{ minHeight: "200px" }} className="line"></h5>
                      <div className="">
                        <div className="p-3">
                          <div className="d-flex align-items-center">
                            <p className="line"></p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="shimmer-anim"></div>
                  </div>
                </div>
              ))}
            </>
          )}
        </div>
        <div className="row">
          <div className="col-12 justify-content-center  text-center">
            <Link to="/blog-listing-sports-news-pakistan" className="fill  ">
              <button>View More</button>
            </Link>
          </div>
        </div>
      </div>
    </section>
  );
};

export default BlogList;
