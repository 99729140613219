import invoke from "../../utils/invoke";
import { Config } from "../../Config/Config";

export const getBlog = (page) => {
  return invoke({
    method: "GET",
    baseURL: Config.baseuUrl,
    route: `blogs/get-all/?page=${page}`,
  });
};

export const getBlogDetail = (slug) => {
  return invoke({
    method: "GET",
    baseURL: Config.baseuUrl,
    route: `blogs/detail/${slug}`,
  });
};
export const getAllSponserVenues = (page) => {
  return invoke({
    method: "GET",
    baseURL: Config.baseuUrl,
    route: `venues/get-all/?page=${page}`,
  });
};

export const getAllSponserVenuesDetail = (slug) => {
  return invoke({
    method: "GET",
    baseURL: Config.baseuUrl,
    route: `venues/detail/${slug}`,
  });
};
