export default function Features() {
  return (
    <>
      <section className="Features">
        <div className="container">
          <div className="row justify-content-md-center text-center ">
            <div className="col blog-text mb-5">
              <h2>Your one-stop platform for sports</h2>
            </div>
          </div>

          <div className="row">
            <div className="col-md-12 col-lg-8 offset-lg-2">
              <div className="User-Count bg-light p-4 mb-5">
                <div className="row">
                  <div className="col-md-6">
                    <div class="d-flex align-items-center justify-content-center">
                      <div className="me-3">
                        <img
                          className="Play-Icon"
                          src="../img/Banner/sm-playstore.png"
                        />
                      </div>
                      <div>
                        <h5 className="mb-0">
                          5.0{" "}
                          <span>
                            <img src="../img/star.svg" />
                          </span>
                        </h5>
                        <p class="feature-content m-0">13K+ Ratings</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div class="d-flex align-items-center justify-content-center">
                      <div className="me-3">
                        <img
                          className="Play-Icon"
                          src="../img/Banner/sm-apple.png"
                          alt=""
                        />
                      </div>
                      <div>
                        <h5 className="mb-0">
                          5.0{" "}
                          <span>
                            <img src="../img/star.svg" />
                          </span>
                        </h5>
                        <p class="feature-content m-0">3K+ Ratings</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-12 col-lg-6 my-5">
              <div className="d-flex align-items-center">
                <div>
                  <img src="../img/Banner/booking.png" alt="" />
                </div>
                <div>
                  <h5 className="mb-1">Quick Booking</h5>
                  <p>
                    Check availability and book at no extra booking fees! Avail
                    discounts on online transactions.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-12 col-lg-6 my-5">
              <div className="d-flex align-items-center">
                <div>
                  <img src="../img/Banner/map.png" />
                </div>
                <div>
                  <h5 className="mb-1">MAP BASED SEARCH</h5>
                  <p>Locate the venue on Gmap look for directions.</p>
                </div>
              </div>
            </div>
            <div className="col-md-12 col-lg-6 my-5">
              <div className="d-flex align-items-center">
                <div>
                  <img src="../img/Banner/payments.png" />
                </div>
                <div>
                  <h5 className="mb-1">EASY PAYMENTS</h5>
                  <p>
                    Multiple payment option like credit card, internet banking,
                    mobile wallet or cash.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-12 col-lg-6 my-5">
              <div className="d-flex align-items-center">
                <div>
                  <img src="../img/Banner/listing.png" />
                </div>
                <div>
                  <h5 className="mb-1">MAXIMUM LISTINGS</h5>
                  <p>
                    Easy Access to maximum sports venue and sports events in
                    your city.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-12">
              <div class="bg-light my-5 d-flex align-items-center justify-content-around Feature-Count py-5 px-4">
                <div className="text-center">
                  <h3 className="mb-2">7K+</h3>
                  <p className="mb-0">Users</p>
                </div>
                <div className="text-center">
                  <h3 className="mb-2">50+</h3>
                  <p className="mb-0">Sports</p>
                </div>
                <div className="text-center">
                  <h3 className="mb-2">6K+</h3>
                  <p className="mb-0">Sports Activities Enabled</p>
                </div>
                <div className="text-center">
                  <h3 className="mb-2">4K+</h3>
                  <p className="mb-0">Player Connections Enabled</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
