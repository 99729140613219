export default function WhoWeAre() {
  return (
    <>
      <section className="team-section">
        <div className="container pt-4 pb-4">
          <div className="row text-center wwr">
            <div className="col-sm-12 col-md-8 offset-md-2">
              <h4
                className="heading"
                style={{ fontSize: "3rem", fontWeight: "700" }}
              >
                WHO WE ARE
              </h4>
              <h6>
                <span>
                  Consider us the Pakistan’s largest open-door digital sports
                  club where you may make reservations on demand from any
                  location at no additional charge.{" "}
                </span>
              </h6>
            </div>
          </div>
          <div className="row pointer">
            {/* ---------------------------1 */}
            <div
              className="col-12 col-lg-4"
              id="ourservice"
              data-aos="fade-up"
              data-aos-duration="1500"
            >
              <div className="card blog_card p-3">
                <img src="../img/about/Service.png" alt="" />
                <div className="card-body px-0">
                  <h5>Our Service.</h5>
                  <p className="card-text">
                    We facilitate fast booking playgrounds in Lahore for
                    sporting venues by displaying popular nearby places for
                    simple booking. Online payments are based on the reserved
                    ground and allow keeping an eye on reservations and
                    forthcoming deals.
                  </p>
                </div>
              </div>
            </div>
            <div
              className="col-12 col-lg-4"
              id="ourcompany"
              data-aos="fade-up"
              data-aos-duration="1500"
            >
              <div className="card blog_card p-3">
                <img src="../img/about/Company.png" alt="" />
                <div className="card-body px-0">
                  <h5>Our Comapany.</h5>
                  <p className="card-text">
                    We let the world play so we build the highest quality and
                    safest playgrounds list to the players of every age. We’ve
                    been working to facilitate and encourage the recreational
                    activities accessible to stay healthy and productive.{" "}
                  </p>
                </div>
              </div>
            </div>
            <div
              className="col-12 col-lg-4"
              id="team"
              data-aos="fade-up"
              data-aos-duration="1500"
            >
              <div className="card blog_card p-3">
                <img src="../img/about/Business vision-pana.png" alt="" />
                <div className="card-body px-0">
                  <h5>Our Director.</h5>
                  <p className="card-text">
                    Sports and games are significant components of most people's
                    life. Professional players somehow manage but others get
                    busy in daily routine. However, once they begin working,
                    sports become a secondary concern. Meidan is supposed to be
                    an all-working app, in my opinion.{" "}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
