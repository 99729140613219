import { Link } from "react-router-dom";
import React, { Component, useEffect, useState } from "react";
import { getAllSponserVenues, getAllSponserVenuesDetail, getBlog } from "../../../api/Blog/Blog";
import { getBlogDetail } from "../../../api/Blog/Blog";
import DocumentMeta from "react-document-meta";
import { useParams } from "react-router-dom";
import { Config } from "../../../Config/Config";
// import { data } from "jquery";

function SponseredVenuesdetails() {
  const [getBlogsDetail, setGetBlogsDetail] = useState();
  const [getBlogs, setGetBlogs] = useState([]);
  const [getMetaTitle, setMetaTitle] = useState();
  const [getMetaDescription, setMetaDescription] = useState();
  const [getMetaTags, setMetaTags] = useState();
  let slug = useParams();

  const currentURL = window.location.href;

  const meta = {
    title: getMetaTitle,
    description: getMetaDescription,
    canonical: currentURL,
    meta: {
      charset: "utf-8",
      name: {
        keywords: getMetaTags,
      },
    },
  };

  useEffect(() => {
    getAllSponserVenuesDetails(slug.id);
    getBlogListing();
  }, []);

  const getAllSponserVenuesDetails = async (slug) => {
    try {
      const response = await getAllSponserVenuesDetail(slug);
      const data = response.data;
      setGetBlogsDetail(data);
      setMetaTitle(data.meta_tags.meta_title);
      setMetaDescription(data.meta_tags.meta_description);
      setMetaTags(data.meta_tags.meta_tags);
    } catch (error) {}
  };
  const getBlogListing = async () => {
    try {
      const response = await getAllSponserVenues();
      const data = response.data;
      let array = [...data.data];
      const uniqueArray = array.filter(
        (item, index, self) => index === self.findIndex((t) => t.id === item.id)
      );
      setGetBlogs(uniqueArray);
    } catch (error) {}
  };

  return (
    <>
      <DocumentMeta {...meta}>
        <section className="d-flex justify-content-center align-items-center MerchantBanner Banner">
          <div className="" data-aos="fade-up" data-aos-duration="1500">
            <h2>{getBlogsDetail?.title}</h2>
            {/* <h6>Meidan, Your Game Partner</h6> */}
          </div>
        </section>

        <section>
          <div className="container ">
            <div className="row">
              <div
                className="col-12 "
                data-aos="fade-up"
                data-aos-duration="1500"
              >
                <h2 className="pt-4">{getBlogsDetail?.title}</h2>
           
              </div>
              <div className="row">
                <div className="col-lg-12 mt-4">
                  <p>{getBlogsDetail?.body1}</p>
                  <div className="text-center py-3 mb-4" style={{height:"100%" ,width:"100%"}}>
                  <img
                    className="blog-img w-100"
                    
                    src={`${Config.baseuUrl}${getBlogsDetail?.background1}`}
                    alt=""
                  />
                </div>
                </div>
              
                <div className="col-lg-12 mt-4">
                  <p>{getBlogsDetail?.body2}</p>
                </div>
                <div className="col-lg-12 mt-4">
                <img
                    className="blog-img w-100"
                    
                    src={`${Config.baseuUrl}${getBlogsDetail?.background2}`}
                    alt=""
                  />
                </div>
                <div className="col-lg-12 mt-4">
                  <p>{getBlogsDetail?.body3}</p>
                </div>
                <div className="col-lg-12 mt-4">
                <img
                    className="blog-img w-100"
                    
                    src={`${Config.baseuUrl}${getBlogsDetail?.background3}`}
                    alt=""
                  />
                </div>
                <div className="col-lg-12 mt-4">
                  <p>{getBlogsDetail?.body4}</p>
                </div>
              </div>
              <div className="col-md-12">

                <div
                  dangerouslySetInnerHTML={{
                    __html: getBlogsDetail?.body,
                  }}
                />
                {getBlogsDetail?.tags &&
                  getBlogsDetail.tags.split(" ").map((tag, index) => (
                    <span
                      style={{ border: "1px solid #0aa70a" }}
                      className="px-3 d-inline-block mt-3 me-3 py-2 rounded-2"
                      key={index}
                    >
                      {tag}
                    </span>
                  ))}
          
              </div>
              {/* <div className="col-md-4">
                {getBlogs.length > 0 ? (
                  getBlogs.map((items) => (
                    <Link to={`/blog-detail/${items.slug}`}>
                      <div className="row py-3 m-blog">
                        <div className="col-4">
                          <img
                            src={`https://blogs.meidan.pk${items.display}`}
                            alt=""
                          />
                        </div>
                        <div className="col-8">
                          <h6>{items.title}</h6>
                          <p className="m-0" style={{ fontSize: "x-small" }}>
                            {items.parse_body.length > 300
                              ? `${items.parse_body.slice(0, 70)}...`
                              : items.parse_body}
                            &nbsp;
                            <Link className="view-more" to="/">
                              Read More
                            </Link>
                          </p>
                        </div>
                      </div>
                    </Link>
                  ))
                ) : (
                  <p>No Data Found...</p>
                )}
              </div> */}
            </div>
          </div>
        </section>
      </DocumentMeta>
    </>
  );
}

export default SponseredVenuesdetails;
