import React, { useEffect, useState } from "react";
import { getAllSponserVenues, getBlog } from "../../../api/Blog/Blog";
import { Link } from "react-router-dom";
import { Config } from "../../../Config/Config";

const SponseredVenues = () => {
  const [totalPages, setTotalPages] = useState(1);
  const [getBlogs, setGetBlogs] = useState([]);
  const [groundData, setGroundData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1); // Track current page

  useEffect(() => {
    getSponserVenues(currentPage);
    window.scrollTo(0, 0);
  }, [currentPage]);

  const getSponserVenues = async (page) => {
    try {
      const response = await getAllSponserVenues(page);
      const data = response.data;

      console.log("Test", data);
      let array = [...data.data];
      const uniqueArray = array.filter(
        (item, index, self) => index === self.findIndex((t) => t.id === item.id)
      );
      console.log("Bilal This is data of Blog", uniqueArray);
      setGroundData(uniqueArray);
      setTotalPages(data.total_page);
    } catch (error) {}
  };

  return (
    <div className="container pt-5">
      <h2 className="text-center" style={{ fontSize: "40px" }}>
        Ground Bookings Are Available in Lahore
      </h2>
      <div className="row pt-5">
        {groundData.length === 0 ? (
          <>
            {Array.from({ length: 8 }).map((_, index) => (
              <div className="col-lg-3 mb-4">
                <div className="shimmer">
                  <div>
                    <label className="line"></label>
                    <h5 style={{ minHeight: "200px" }} className="line"></h5>
                    <div className="">
                      <div className="p-3">
                        <div className="d-flex align-items-center">
                          <p className="line"></p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="shimmer-anim"></div>
                </div>
              </div>
            ))}
          </>
        ) : (
          groundData.map((item) => (
            <div className="col-lg-3">
              {/* <Link
                        to={{
                          pathname: "/venues-detail",
                          state: { groundDetail: item },
                        }}
                      > */}
              <div>
                <Link
                  to={`/sponservenues/${item.slug}`}
                  key={item.id}
                  data-aos="fade-up"
                  data-aos-duration="1500"
                >
                  <div className="product-spo">
                    <figure className="product-media-spo">
                      <a href>
                        <img
                          src={`${Config.baseuUrl}${item.display}`}
                          alt="Category"
                        />
                      </a>
                      <div className="product-label-group">
                        <label className="product-label label-new ">Hot</label>
                      </div>
                    </figure>
                    <div className="d-flex justify-content-between">
                      <div className="p-3">
                        <h5 className="text-black ">{item.title}</h5>
                        <p className="fixer">
                          {item?.city}, {item?.area}, {item?.address?.country}
                        </p>
                        <p>{item.games}</p>
                      </div>
                      <div className="p-3">
                        <div className="d-flex align-items-center">
                          <h6
                            className="text-black mb-0"
                            style={{ fontWeight: "400" }}
                          >
                            {item.rating}
                          </h6>
                          <img
                            style={{ position: "relative", top: "-10px" }}
                            width={15}
                            src="../img/star.svg"
                            alt=""
                          />
                        </div>
                      </div>
                    </div>
                    {/* <a
                      className="book-now position"
                      href="https://play.google.com/store/apps/details?id=com.meidanuserapp"
                      target="_blank"
                    >
                      Book Now
                    </a> */}
                  </div>
                </Link>
              </div>
            </div>
          ))
        )}
      </div>
    </div>
  );
};

export default SponseredVenues;
