import React from "react";

const AreaGround = () => {
  return (
    <div>
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <h2 className="text-center mb-5">List of Sports Venues in Lahore</h2>
            <ul className="text-center list-inline why-meidan-list">
              <li>
                <a href="javascript:void(0)">Cantt Sports Grounds</a>
              </li>
              <li>
                <a href="javascript:void(0)">Johar Town Sports Grounds</a>
              </li>
              <li>
                <a href="javascript:void(0)">Gulberg Sports Grounds</a>
              </li>
              <li>
                <a href="javascript:void(0)">DHA Sports Grounds</a>
              </li>
              <li>
                <a href="javascript:void(0)">Gari Shahu Sports Grounds</a>
              </li>
              <li>
                <a href="javascript:void(0)">Shad Bagh Sports Grounds</a>
              </li>
              <li>
                <a href="javascript:void(0)">Walton Sports Grounds</a>
              </li>
              <li>
                <a href="javascript:void(0)">Wapda Town Sports Grounds</a>
              </li>
              <li>
                <a href="javascript:void(0)">Bedian Road Sports Grounds</a>
              </li>
              <li>
                <a href="javascript:void(0)">Zaman Park Sports Grounds</a>
              </li>
              <li>
                <a href="javascript:void(0)">Mall Road Sports Grounds</a>
              </li>
              <li>
                <a href="javascript:void(0)">Ravi Sports Grounds</a>
              </li>
              <li>
                <a href="javascript:void(0)">Shadman Sports Grounds</a>
              </li>
              <li>
                <a href="javascript:void(0)">Jailroad Sports Grounds</a>
              </li>
              <li>
                <a href="javascript:void(0)">Mazang Sports Grounds</a>
              </li>
              <li>
                <a href="javascript:void(0)">Manawa Sports Grounds</a>
              </li>
              <li>
                <a href="javascript:void(0)">Chuburji Sports Grounds</a>
              </li>
              <li>
                <a href="javascript:void(0)">Valencia Town Sports Grounds</a>
              </li>
              <li>
                <a href="javascript:void(0)">Rehman Garden Sports Grounds</a>
              </li>
              <li>
                <a href="javascript:void(0)">Shalamar Sports Grounds</a>
              </li>
              <li>
                <a href="javascript:void(0)">Samanabad Sports Grounds</a>
              </li>
              <li>
                <a href="javascript:void(0)">Iqbal Town Sports Grounds</a>
              </li>
              <li>
                <a href="javascript:void(0)">Nishtar Colony Sports Grounds</a>
              </li>
              <li>
                <a href="javascript:void(0)">Model Town Sports Grounds</a>
              </li>
              <li>
                <a href="javascript:void(0)">Calvalry Sports Grounds</a>
              </li>
              <li>
                <a href="javascript:void(0)">Faisal Town Sports Grounds</a>
              </li>
              <li>
                <a href="javascript:void(0)">Garden Town Sports Grounds</a>
              </li>
              <li>
                <a href="javascript:void(0)">Taj Bagh Sports Grounds</a>
              </li>
              <li>
                <a href="javascript:void(0)">Walled City Sports Grounds</a>
              </li>
              <li>
                <a href="javascript:void(0)">Sabzazar Sports Grounds</a>
              </li>
              <li>
                <a href="javascript:void(0)">LDA Sports Grounds</a>
              </li>
              <li>
                <a href="javascript:void(0)">Kalma Chawk Sports Grounds</a>
              </li>
              <li>
                <a href="javascript:void(0)">Canal Road Sports Grounds</a>
              </li>
              <li>
                <a href="javascript:void(0)">Township Sports Grounds</a>
              </li>
              <li>
                <a href="javascript:void(0)">China Scheme Sports Grounds</a>
              </li>
              <li>
                <a href="javascript:void(0)">MM Alam Road Sports Grounds</a>
              </li>
              <li>
                <a href="javascript:void(0)">Gulshan Ravi Sports Grounds</a>
              </li>
              <li>
                <a href="javascript:void(0)">Data Ganj Bakhsh Sports Grounds</a>
              </li>
              <li>
                <a href="javascript:void(0)">Wahdat Road Sports Grounds</a>
              </li>
              <li>
                <a href="javascript:void(0)">Muslim Town Sports Grounds</a>
              </li>
              <li>
                <a href="javascript:void(0)">Bahria Town Sports Grounds</a>
              </li>
              <li>
                <a href="javascript:void(0)">Anarkali Sports Grounds</a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AreaGround;
