import React, { useEffect, useState } from "react";

const DiverseAreas = () => {
  return (
    <div className="container pt-5">
      <h2 className="text-center" style={{ fontSize: "40px" }}>
        Find Your Play
      </h2>
      <p className="text-center">
        Find sports facilities in diverse areas of Lahore, Pakistan.
      </p>
      <div className="row pt-5">
        <div className="col-lg-4">
          <div>
            <div className="product-spo diverse-area">
              <figure className="product-media-spo">
                <img src="../img/Banner/johartown.jpeg" alt="Category" />
              </figure>
              <div className="d-flex justify-content-between">
                <div className="p-3">
                  <h5 className="text-black ">
                    Luxury sports ground of Johar Town
                  </h5>
                </div>
                <a
                  class="book-now position"
                  href="https://play.google.com/store/apps/details?id=com.meidanuserapp"
                  target="_blank"
                >
                  Book Now
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-4">
          <div>
            <div className="product-spo diverse-area">
              <figure className="product-media-spo">
                <img src="../img/Banner/dha.png" alt="Category" />
              </figure>
              <div className="d-flex justify-content-between">
                <div className="p-3">
                  <h5 className="text-black ">
                    Comfortable Sport Complexes of DHA
                  </h5>
                </div>
                <a
                  class="book-now position"
                  href="https://play.google.com/store/apps/details?id=com.meidanuserapp"
                  target="_blank"
                >
                  Book Now
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-4">
          <div>
            <div className="product-spo diverse-area">
              <figure className="product-media-spo">
                <img src="../img/Banner/cantt.jpg" alt="Category" />
              </figure>
              <div className="d-flex justify-content-between">
                <div className="p-3">
                  <h5 className="text-black ">Leisure Sports Clubs of Cantt</h5>
                </div>
                <a
                  class="book-now position"
                  href="https://play.google.com/store/apps/details?id=com.meidanuserapp"
                  target="_blank"
                >
                  Book Now
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DiverseAreas;
