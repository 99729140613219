import "./blogbanner.css";
export default function BlogBanner() {
  return (
    <>
      <section className="d-flex justify-content-center align-items-center BlogBanner Banner">
        <div className="" data-aos="fade-up" data-aos-duration="1500">
          <h2>Blogs</h2>
          <h6>
            Meidan is the Pakistan’s top search and booking site for
            recreational sports.
          </h6>
        </div>
      </section>
    </>
  );
}
