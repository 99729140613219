export default function OwnerBenefit() {
  return (
    <>
      <section className="bg-light">
        <div className=" py-5 service-11">
          <div className="container">
            <div className="row text-center">
              <h2>Owner benefits:</h2>
              <p className="text-muted text-center">
                Here are some interesting benefits for merchant Meidan App to
                enjoy business growth exponentially. No other platform can
                provide such amazing benefits.{" "}
              </p>
            </div>
            <div className="row">
              <div className="col-lg-4 col-md-6 col-12 wrap-service11-box">
                <div
                  className="card benefitCard card-shadow border-0 mb-4"
                  style={{ overflow: "unset" }}
                  data-aos="fade-up"
                  data-aos-duration="1500"
                >
                  <div className="p-4">
                    <div className="icon-space">
                      <div className="icon-round text-center d-inline-block rounded-circle bg-success-gradiant">
                        <img
                          className="icon-d"
                          src="../img/become-a-merchant/1.svg"
                          alt=""
                          style={{ paddingBottom: "14px" }}
                        />
                      </div>
                    </div>
                    <h6 className="">Easily manageable:</h6>
                    <p className="mt-3">
                      The Meidan app is easily manageable for sports ground
                      listing from anywhere anytime. It can even work in other
                      countries as well. You would just need an internet
                      connection to manage it.
                    </p>
                    <br />
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-12 wrap-service11-box">
                <div
                  className="card benefitCard card-shadow border-0 mb-4"
                  style={{ overflow: "unset" }}
                  data-aos="fade-up"
                  data-aos-duration="1500"
                >
                  <div className="p-4">
                    <div className="icon-space">
                      <div className="icon-round text-center d-inline-block rounded-circle bg-success-gradiant">
                        <img
                          className="icon-d"
                          src="../img/become-a-merchant/2.svg"
                          alt=""
                          style={{ paddingBottom: "14px" }}
                        />
                      </div>
                    </div>
                    <h6 className="">Eliminate book keeping system</h6>
                    <p className="mt-3">
                      Maintaining journals is the old way of keeping records. It
                      takes time, energy, and labor. After all the efforts, it
                      can be destroyed and replaced so easily. Registration form
                      for Meidan merchants are available online.
                    </p>
                    <br />
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-12 wrap-service11-box">
                <div
                  className="card benefitCard card-shadow border-0 mb-4"
                  style={{ overflow: "unset" }}
                  data-aos="fade-up"
                  data-aos-duration="1500"
                >
                  <div className="p-4">
                    <div className="icon-space">
                      <div className="icon-round text-center d-inline-block rounded-circle bg-success-gradiant">
                        <img
                          className="icon-d"
                          src="../img/become-a-merchant/3.svg"
                          alt=""
                        />
                      </div>
                    </div>
                    <h6 className="">More users than regular old system</h6>
                    <p className="mt-3">
                      As we all know people have shifted to internet and online
                      search for desire problem. Like other developed countries,
                      Meidan has also decided to make things easier than before
                      for Pakistanis.
                    </p>
                    <br />
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-12 wrap-service11-box">
                <div
                  className="card benefitCard card-shadow border-0 mb-4"
                  style={{ overflow: "unset" }}
                  data-aos="fade-up"
                  data-aos-duration="1500"
                >
                  <div className="p-4">
                    <div className="icon-space">
                      <div className="icon-round text-center d-inline-block rounded-circle bg-success-gradiant">
                        <img
                          className="icon-d"
                          src="../img/become-a-merchant/4.svg"
                          alt=""
                          style={{ paddingBottom: "14px" }}
                        />
                      </div>
                    </div>
                    <h6 className="">More profit</h6>
                    <p className="mt-3">
                      Meidan is solving the hurdle to track customers and book
                      appointments by sports ground listing. It enhances the
                      business and removes the problems of access. This will
                      automatically generate more customers and end up with a
                      larger amount of profit.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-12 wrap-service11-box">
                <div
                  className="card benefitCard card-shadow border-0 mb-4"
                  style={{ overflow: "unset" }}
                  data-aos="fade-up"
                  data-aos-duration="1500"
                >
                  <div className="p-4">
                    <div className="icon-space">
                      <div className="icon-round text-center d-inline-block rounded-circle bg-success-gradiant">
                        <img
                          className="icon-d"
                          src="../img/become-a-merchant/5.svg"
                          alt=""
                        />
                      </div>
                    </div>
                    <h6 className="">Referral download reward RS 50</h6>
                    <p className="mt-3">
                      Merchant will reward RS 50 to his Meidan Wallet, only if
                      he refers any user to sports ground listing through the
                      Meidan App. And the one who is a referral will reward RS
                      50 to the Meidan Wallet.
                    </p>
                    <br />
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-12 wrap-service11-box">
                <div
                  className="card benefitCard card-shadow border-0 mb-4"
                  style={{ overflow: "unset" }}
                  data-aos="fade-up"
                  data-aos-duration="1500"
                >
                  <div className="p-4">
                    <div className="icon-space">
                      <div className="icon-round text-center d-inline-block rounded-circle bg-success-gradiant">
                        <img
                          className="icon-d"
                          src="../img/become-a-merchant/6.svg"
                          alt=""
                        />
                      </div>
                    </div>
                    <h6 className="">
                      Merchant has Peak hours selection power
                    </h6>
                    <p className="mt-3">
                      Peak hour is amazing feature of the Meidan App. Merchant
                      has its own power to select peak hours to earn more
                      profit. There isn’t any restriction from Company as there
                      is no power limit to earn.
                    </p>
                    <br />
                  </div>
                </div>
              </div>
              <div className="col-lg-4  col-md-6 col-12 wrap-service11-box">
                <div
                  className="card benefitCard card-shadow border-0 mb-4"
                  style={{ overflow: "unset" }}
                  data-aos="fade-up"
                  data-aos-duration="1500"
                >
                  <div className="p-4">
                    <div className="icon-space">
                      <div className="icon-round text-center d-inline-block rounded-circle bg-success-gradiant">
                        <img
                          className="icon-d"
                          src="../img/become-a-merchant/7.svg"
                          alt=""
                          style={{ paddingBottom: "14px" }}
                        />
                      </div>
                    </div>
                    <h6 className="">
                      No freemium, only premium even more premium than usual{" "}
                    </h6>
                    <p className="mt-3">
                      No merchant is liable to give freemium to any customer.
                      Merchant is independent for choosing charges from the
                      ground user.{" "}
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-12 wrap-service11-box">
                <div
                  className="card benefitCard card-shadow border-0 mb-4"
                  style={{ overflow: "unset" }}
                  data-aos="fade-up"
                  data-aos-duration="1500"
                >
                  <div className="p-4">
                    <div className="icon-space">
                      <div className="icon-round text-center d-inline-block rounded-circle bg-success-gradiant">
                        <img
                          className="icon-d"
                          src="../img/become-a-merchant/8.svg"
                          alt=""
                          style={{ paddingBottom: "4px" }}
                        />
                      </div>
                    </div>
                    <h6 className="">Cancelation profit to merchant </h6>
                    <p className="mt-3">
                      Whenever a user cancels its booking of ground, the
                      merchant will charge him particular amount. As the
                      cancelation chart is already maintained.
                    </p>
                    <br />
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-12 wrap-service11-box">
                <div
                  className="card benefitCard card-shadow border-0 mb-4"
                  style={{ overflow: "unset" }}
                  data-aos="fade-up"
                  data-aos-duration="1500"
                >
                  <div className="p-4">
                    <div className="icon-space">
                      <div className="icon-round text-center d-inline-block rounded-circle bg-success-gradiant">
                        <img
                          className="icon-d"
                          src="../img/become-a-merchant/9.svg"
                          alt=""
                          style={{ paddingBottom: "14px" }}
                        />
                      </div>
                    </div>
                    <h6 className="">Easy to use and healthy income </h6>
                    <p className="mt-3">
                      It’s not difficult to use and manage. Even it clears all
                      the barrier of booking grounds. Merchant can earn ten
                      times more than usual income.
                    </p>
                    <br />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
