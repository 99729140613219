import React, { useEffect, useState } from "react";
import Carousel from "better-react-carousel";
import axios from "axios";
import { useParams, useLocation } from "react-router-dom";

const Venuesdetails = (props) => {
  const { location } = props;
  console.log("da ", location);

  const { groundDetail } = location.state;

  return (
    <div>
      <div className="breadcrumb-area pt-175 pb-70 relative">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="row pt-5">
                <div className="col-lg-8">
                  <h2>{groundDetail?.name}</h2>
                  <div className="d-flex align-items-center pt-1 pb-3">
                    <p style={{ marginBottom: "0px", marginRight: "5px" }}>
                      {groundDetail?.address?.street_address} .{" "}
                    </p>
                    <div className="d-flex align-items-center">
                      <img src="../img/star.svg" alt="" />
                      <p
                        style={{ marginBottom: "0px", marginLeft: "2px" }}
                        className="text-black"
                      >
                        {groundDetail?.total_reviews}
                      </p>
                      <p style={{ marginBottom: "0px", marginLeft: "2px" }}>
                        (100 ratings)
                      </p>
                    </div>
                  </div>
                  <img
                    className=" pb-5"
                    style={{ width: "100%", borderRadius: "10px" }}
                    src={groundDetail?.background}
                  />
                  <div className="amenities d-flex align-items-center flex-wrap mb-3">
                    <div className="d-flex align-items-center p-3">
                      <img src="../img/correct.svg" alt="" />
                      <p
                        style={{ margin: "0px", marginLeft: "5px" }}
                        className="text-black"
                      >
                        Parking
                      </p>
                    </div>
                    <div className="d-flex align-items-center p-3">
                      <img src="../img/correct.svg" alt="" />
                      <p
                        style={{ margin: "0px", marginLeft: "5px" }}
                        className="text-black"
                      >
                        Washrooms
                      </p>
                    </div>
                    <div className="d-flex align-items-center p-3">
                      <img src="../img/correct.svg" alt="" />
                      <p
                        style={{ margin: "0px", marginLeft: "5px" }}
                        className="text-black"
                      >
                        Change Rooms
                      </p>
                    </div>
                    <div className="d-flex align-items-center p-3">
                      <img src="../img/correct.svg" alt="" />
                      <p
                        style={{ margin: "0px", marginLeft: "5px" }}
                        className="text-black"
                      >
                        Tuck Shop
                      </p>
                    </div>
                  </div>
                  <div className="sports-section mb-3">
                    <h5>Sports Available</h5>
                    <div className="d-flex align-items-center flex-wrap">
                      <div className="inner">
                        <h6>{groundDetail?.games[0]}</h6>
                      </div>
                      <div className="inner">
                        <h6>{groundDetail?.games[1]}</h6>
                      </div>
                    </div>
                  </div>
                  <div className="amenities">
                    <p className="text-black">Cricket:</p>
                    <ul>
                      <li>
                        {" "}
                        It is recommended but not compulsory to wear cricket
                        studs while playing at the facility.
                      </li>
                      <li> Metal studs are not allowed.</li>
                    </ul>
                    <p className="text-black">Football:</p>
                    <ul>
                      <li>
                        {" "}
                        It is recommended but not compulsory to wear football
                        studs while playing at the facility.
                      </li>
                      <li> Metal studs are not allowed.</li>
                    </ul>
                  </div>
                </div>

                <div className="col-lg-4 ">
                  <a href="#" className="">
                    <button className="book-now mt-5 mb-3">Book Now</button>
                  </a>
                  <div className="pt-3 sports-section">
                    <h5 className="pt-1 pb-3">Timmings</h5>
                    <div className="timmimg-sots">
                      <div className="row">
                        <div className="col-lg-4">
                          <div className="timming">6pm-12pm</div>
                        </div>
                        <div className="col-lg-4">
                          <div className="timming">6pm-12pm</div>
                        </div>
                        <div className="col-lg-4">
                          <div className="timming">6pm-12pm</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Venuesdetails;
