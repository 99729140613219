import { Route, BrowserRouter, Switch } from "react-router-dom";
import Footer from "./Components/Layout/Footer";
import Hero from "./Components/Layout/Hero";
import Home from "./Components/Home/Home";
import Terms from "./Components/Terms/Term";
import HowitWorks from "./Components/HowItWorks/HowitWorks";
import Contact from "./Components/Contact/ContactUs";
import About from "./Components/About/AboutUs";
import Merchant from "./Components/Merchant/Merchant";
import BlogListing from "./Components/Blogs/Landing/Landing";
import BlogDetail from "./Components/Blogs/BlogDetails/BlogDetails.jsx";
import AvanueList from "./Components/Avanue/AvanueList.jsx";
import BookVenue from "./Components/Avanue/BookVenue/BookVenue";

// import "bootstrap/dist/css/bootstrap.min.css";

import ScrollToTop from "./Components/ScrollToTop.js";
import { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import Venuesdetails from "./Components/Avanue/Venuesdetails.jsx";
import { Provider } from "react-redux";
import store from "./redux/store.js";
import BlackHeader from "./Components/Layout/BlackHeader";
import SponseredVenuesdetails from "./Components/Home/Layout/SponseredVenuesdetails.jsx";

function App() {
  useEffect(() => {
    AOS.init();
  }, []);

  return (
    <Provider store={store}>
      <BrowserRouter>
        <ScrollToTop />
        {/* <CustomHeader /> */}
        <Switch>
          <div className="Wrapper">
            <Route exact path="*" component={BlackHeader} />
            <Route exact path="/" component={Home} />
            <Route exact path="/terms-and-conditions" component={Terms} />
            <Route
              exact
              path="/book-playgrounds-in-Lahore"
              component={HowitWorks}
            />
            <Route exact path="/contact-us" component={Contact} />
            <Route
              exact
              path="/sports-ground-booking-in-Lahore"
              component={About}
            />
            <Route exact path="/merchant-account" component={Merchant} />
            <Route exact path="/find-your-ground" component={AvanueList} />
            <Route
              exact
              path="/blog-listing-sports-news-pakistan"
              component={BlogListing}
            />
            <Route exact path="/blog-detail/:id" component={BlogDetail} />
            <Route
              exact
              path="/sponservenues/:id"
              component={SponseredVenuesdetails}
            />

            <Route exact path="/venues-detail" component={Venuesdetails} />
            <Route exact path="/book-venues" component={BookVenue} />

            <Route exact path="*" component={Hero} />
            <Route exact path="*" component={Footer} />
          </div>
        </Switch>
      </BrowserRouter>
    </Provider>
  );
}

export default App;
