const Faq = () => {
  return (
    <>
      <section id="FAQ">
        <div className="accordion accordion-flush" id="accordionFlushExample">
          <div className="container">
            <div className="row justify-content-md-center ">
              <div className="col12">
                <h1 className="text-center mb-4">FAQ's</h1>
              </div>
              <div
                className="accordion-item col-12 col-md-10 col-lg-10"
                data-aos="fade-up"
                data-aos-duration="1500"
              >
                <h5 className="accordion-header" id="flush-headingOne">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#flush-collapseOne"
                    aria-expanded="false"
                    aria-controls="flush-collapseOne"
                  >
                    What is Meidan application for?
                  </button>
                </h5>
                <div
                  id="flush-collapseOne"
                  className="accordion-collapse collapse"
                  aria-labelledby="flush-headingOne"
                  data-bs-parent="#accordionFlushExample"
                >
                  <div className="accordion-body">
                    It is a service based platform to provide you access to
                    playgrounds. It makes easy to find desired location near or
                    far to play sport. Basic reason is to increase modest usage
                    of sports for everyone.{" "}
                  </div>
                </div>
              </div>
              <div
                className="accordion-item col-12 col-md-10 col-lg-10"
                data-aos="fade-up"
                data-aos-duration="1500"
              >
                <h5 className="accordion-header" id="flush-headingTwo">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#flush-collapseTwo"
                    aria-expanded="false"
                    aria-controls="flush-collapseTwo"
                  >
                    How can we become merchants on Meidan?
                  </button>
                </h5>
                <div
                  id="flush-collapseTwo"
                  className="accordion-collapse collapse"
                  aria-labelledby="flush-headingTwo"
                  data-bs-parent="#accordionFlushExample"
                >
                  <div className="accordion-body">
                    Anyone can become a merchant on Meidan. He/She only have to
                    fill KYC form given. Within 24 hours Meidan team will
                    contact you to approve your KYC form.
                  </div>
                </div>
              </div>
              <div
                className="accordion-item col-12 col-md-10 col-lg-10"
                data-aos="fade-up"
                data-aos-duration="1500"
              >
                <h5 className="accordion-header" id="flush-headingThree">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#flush-collapseThree"
                    aria-expanded="false"
                    aria-controls="flush-collapseThree"
                  >
                    How long it takes for KYC approval?
                  </button>
                </h5>
                <div
                  id="flush-collapseThree"
                  className="accordion-collapse collapse"
                  aria-labelledby="flush-headingThree"
                  data-bs-parent="#accordionFlushExample"
                >
                  <div className="accordion-body">
                    It takes only 24 hour for approval. Meidan team will contact
                    you to ask questions related to filled form. Questions will
                    be asked related to merchant's ground, time or payment
                    methods.
                  </div>
                </div>
              </div>
              <div
                className="accordion-item col-12 col-md-10 col-lg-10"
                data-aos="fade-up"
                data-aos-duration="1500"
              >
                <h5 className="accordion-header" id="flush-headingFour">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#flush-collapseFour"
                    aria-expanded="false"
                    aria-controls="flush-collapseFour"
                  >
                    Do we have coupons on Meidan?
                  </button>
                </h5>
                <div
                  id="flush-collapseFour"
                  className="accordion-collapse collapse"
                  aria-labelledby="flush-headingFour"
                  data-bs-parent="#accordionFlushExample"
                >
                  <div className="accordion-body">
                    Yes, Meidan do have coupons. Meidan itself offers you
                    coupons and Ground Owners may also apply custom coupons for
                    users. Coupons can be discounted offer to apply on spot.
                    <code>For instance 25%</code> will be off from total
                    payment, if user book particular ground now.
                  </div>
                </div>
              </div>
              <div
                className="accordion-item col-12 col-md-10 col-lg-10"
                data-aos="fade-up"
                data-aos-duration="1500"
              >
                <h5 className="accordion-header" id="flush-headingFive">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#flush-collapseFive"
                    aria-expanded="false"
                    aria-controls="flush-collapseFive"
                  >
                    How can I get merchant app?
                  </button>
                </h5>
                <div
                  id="flush-collapseFive"
                  className="accordion-collapse collapse"
                  aria-labelledby="flush-headingFive"
                  data-bs-parent="#accordionFlushExample"
                >
                  <div className="accordion-body">
                    Meidan team will provide Merchant app after approving KYC.
                    Merchant app is available on Play Store Now.
                  </div>
                </div>
              </div>
              <div
                className="accordion-item col-12 col-md-10 col-lg-10"
                data-aos="fade-up"
                data-aos-duration="1500"
              >
                <h5 className="accordion-header" id="flush-headingSix">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#flush-collapseSix"
                    aria-expanded="false"
                    aria-controls="flush-collapseSix"
                  >
                    What is difference between Meidan user app and Meidan
                    merchant app?
                  </button>
                </h5>
                <div
                  id="flush-collapseSix"
                  className="accordion-collapse collapse"
                  aria-labelledby="flush-headingSix"
                  data-bs-parent="#accordionFlushExample"
                >
                  <div className="accordion-body">
                    <div className="container">
                      <div className="row">
                        <div className="col-6">
                          <b>Merchant app</b>
                        </div>
                        <div className="col-6">
                          <b>User app</b>
                        </div>
                        <div className="col-6">
                          <li> Merchants are service providers.</li>
                        </div>
                        <div className="col-6">
                          <li> Users are the choosers to play.</li>
                        </div>
                        <div className="col-6">
                          <li>
                            {" "}
                            Transactions and bookings will be managed by ground
                            owner himself.
                          </li>
                        </div>
                        <div className="col-6">
                          <li>
                            {" "}
                            User can see payment and time slots to choose
                            according to availability.
                          </li>
                        </div>
                        <div className="col-6">
                          <li>
                            {" "}
                            It is managed by ground owners. It allows owner to
                            upload time slots or pictures of ground.
                          </li>
                        </div>
                        <div className="col-6">
                          <li>
                            {" "}
                            User can search and choose desired grounds near or
                            far.
                          </li>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Faq;
