export default function ContactBanner() {
  return (
    <>
      <section className="d-flex justify-content-center align-items-center MerchantBanner Banner">
        <div className="" data-aos="fade-up" data-aos-duration="1500">
          <h2>Contact US</h2>
          <h6>Meidan, Your Game Partner</h6>
        </div>
      </section>
    </>
  );
}
