import React, { Component } from "react";
import TermsBanner from "./Layout/TermsBanner";

class Term extends Component {
  render() {
    return (
      <div>
        <TermsBanner />
        {/* Content */}
        <section id="TAC">
          <div className="container mt-5">
            <div className="row text-center">
              <div className="col-12">
                <h3
                  className="heading"
                  data-aos="fade-up"
                  data-aos-duration="1500"
                >
                  Terms And Conditions
                </h3>
              </div>
            </div>
            <hr style={{ color: "green", height: "3px" }} />
            <div className="row " data-aos="fade-up" data-aos-duration="1500">
              <p>
                This page contains the terms and conditions that govern your use
                of the websites www. meidan.pk, or any other facility websites
                utilizing our products or services ("Site"), applications for
                mobile, tablet, and other smart devices, and application program
                interfaces (collectively, the "Application"), including by
                accessing and browsing it, printing, copying, and downloading
                from it, posting to it, linking to or from it, or registering
                with it ("Use") (collectively, the "Site Terms"). Read these
                site terms carefully. You attest that you have read,
                comprehended, and agree to all of the terms of the Site Terms by
                continuing to use the Site. You may not use or proceed to use
                this website or application if you do not agree to all of its
                terms.
              </p>
              <br />
              <p>
                Before using the site or application, read these policies
                thoroughly as they regulate your use thereof. We also advise
                that you print a copy of these terms for your records so you can
                refer to them later.
              </p>
              <br />
              <p>
                Your use of this site and application is also governed by Our
                Privacy Policy, which is supplemented and expanded upon by these
                site terms. The personal information we get about you and use
                when you use the site or application is described in our privacy
                statement. You are deemed to have read, understood, and agreed
                to Our Privacy Statement in its entirety by using this site or
                application. You also consent to the processing of your personal
                data and guarantee that any information you supply is accurate.
              </p>
              <br />
              <p>
                These Site Terms and Our Privacy Policy, along with any
                additional legal notices on other portions of the Site and
                Application that are relevant to your use of the Site or
                Application, shall all govern your use.
              </p>
            </div>
            <div
              className="row py-5"
              data-aos="fade-up"
              data-aos-duration="1500"
            >
              <h3>Information about us</h3>
              <p>
                Meidan, a business name of{" "}
                <b>MEIDAN TECHNOLOGIES (PRIVATE) LIMITED</b>, is the company
                that runs this website and application
              </p>
              <br />
              <p>
                We are a Limited company with registration number <b>0218503</b>{" "}
                in Pakistan, Lahore.{" "}
              </p>
              <br />
              <p>
                328L Joher Town, Lahore, Pakistan is the address for our
                registered office.{" "}
              </p>
              <p>
                We represent vendors, owners, operators, clubs, coaches,
                referees, and/or umpires of sporting events, leagues,
                competitions etc.
              </p>
            </div>
            <div
              className="row py-3"
              data-aos="fade-up"
              data-aos-duration="1500"
            >
              <h3>Your use of this site: </h3>
              <p>
                You must only use the published links, messages, materials, and
                information on this site and application (collectively,
                "Content") for lawful purposes, and you must not use them in a
                way that violates the rights of us or anyone else, or that
                limits or inhibits our or anyone else's use and enjoyment of
                this site or application.{" "}
              </p>
              <br />
              <p>
                You are only using this website or application for personal
                purposes. You may not use this site or application:{" "}
              </p>
              <div>
                <ul>
                  <li>
                    to copy, use, market, steal, or otherwise utilize any
                    Content found on this website or application.{" "}
                  </li>
                  <li>
                    to use the Content or any portion of it for commercial
                    purposes (unless you first obtain a permit from Us,&nbsp;in
                    which case We retain the right to charge you for such
                    commercial exploitation).{" "}
                  </li>
                  <li>
                    any action that violates an applicable law or regulation, is
                    illegal or fraudulent, or has an illegal or fraudulent
                    intent or result.{" "}
                  </li>
                  <li>
                    to cause damage or seek to cause harm to kids in any way.{" "}
                  </li>
                  <li>
                    to alter, reproduce, or publicly display any Content found
                    on this website or application.{" "}
                  </li>
                  <li>
                    to send or arrange for the sending of any unsolicited or
                    unauthorized advertising, promotion, or similar solicitation
                    (spam);
                  </li>
                  <li>
                    to clone, crawl, frame, or link to this Site or Application.{" "}
                  </li>
                </ul>
              </div>
              <div>
                <h5>You must not:</h5>
                <ul>
                  <li>
                    intentionally introduce malware such as Trojan horses,
                    worms, logic bombs, or other items that might be damaging to
                    ourselves or others in terms of technology.
                  </li>
                  <li>
                    try to gain unauthorized access to any server, computer, or
                    database linked to this site or application, the server on
                    which it is housed, or any portion of any of those.
                  </li>
                  <li>
                    attack this website or application, including through
                    distributed denial-of-service assaults.{" "}
                  </li>
                  <li>
                    post or distribute any libelous,&nbsp;illegal messages or
                    content (including any that contain another person's
                    confidential or proprietary information, such as trade
                    secrets, confidential business information, or copyrighted
                    information), even if you don't have that person's
                    permission to do so.{" "}
                  </li>
                </ul>
                <p>
                  You agree that any violation of these Site Terms by you may
                  constitute a crime under the Prevention of Electronic Crimes
                  Act (PECA) 2016 (PAK), and that We may notify the appropriate
                  law enforcement authorities of any actual or suspected
                  violations and cooperate with them, including by disclosing
                  your identity or IP address.
                </p>
              </div>
            </div>
            <div
              className="row py-3"
              data-aos="fade-up"
              data-aos-duration="1500"
            >
              <h3>Accuracy of content and your reliance on it: </h3>
              <p>
                You should not depend on the content on this site and
                application as advice of any kind since it is only offered for
                general informational purposes and does not represent
                professional advice of any kind, whether legal or otherwise, or
                an endorsement of any third party.{" "}
              </p>
              <p>
                We reserve the right to monitor, alter, or censor such
                user-generated Content when “We” deem it necessary or acceptable
                to do so. Views expressed in user-generated Content are the
                opinions of those users and do not reflect “Our” views,
                opinions, beliefs, or values.{" "}
              </p>
              <p>
                We disclaim all representations, warranties, and
                guarantees—express or implied—that the Content is true,
                complete, current, and/or does not violate the rights of any
                third party, to the fullest extent permissible by law.{" "}
              </p>
              <p>
                Meidan disclaims all obligations and is not accountable for any
                bookings made for the facilities of our providers. Because of
                this, any reservations you make as a consequence of using the
                site are made at your own risk, and by doing so, you assume full
                responsibility for any losses or damages that arise at a
                Facility as a direct or indirect result of a reservation you
                make for the Facility.{" "}
              </p>
              <p>
                Meidan isn't&nbsp;liable for any loss&nbsp;or damage&nbsp;that
                the supplier (or any person claiming under or through the
                supplier) may sustain, whether such losses or damages are
                direct, indirect, immediate, consequential, or arise from a
                contract, personal injury cases (including negligence), or any
                other cause whatsoever, and fall into any of the following
                categories:{" "}
              </p>
              <div>
                <ul>
                  <li>Damage.</li>
                  <li>loss of profits.</li>
                  <li>loss of anticipated savings.</li>
                  <li>loss of business opportunity.</li>
                  <li>loss of goodwill.</li>
                  <li>
                    any injury or death occur from a booking that Meidan
                    facilitated.
                  </li>
                </ul>
              </div>
            </div>
            <div
              className="row py-3"
              data-aos="fade-up"
              data-aos-duration="1500"
            >
              <h3 className="text-center">Policies: </h3>
              <span className="text-center">
                Pricing, changes to bookings and cancellations:
              </span>
              <div>
                <h5>Permission: </h5>
                <li>
                  App will ask permission to have access to Camera, Gallery and
                  Location.{" "}
                </li>
              </div>
              <div>
                <h5>Purpose of these accesses: </h5>
                <ul>
                  <li>
                    It is to allowed user comfort to upload picture of the
                    ground and play area with all the perks that a player can
                    enjoy.{" "}
                  </li>
                  <li>
                    It is to show nearby grounds located to your current
                    location. So the user may get best matches nearby.
                  </li>
                  <li>
                    Meidan Finder may find grounds by searching specific names
                    and location.
                  </li>
                </ul>
              </div>
            </div>
            <div className="row py-3">
              <div className="row text-center">
                <h3>User: </h3>
              </div>
              <hr />
              <div className="row">
                <h6>Booking cancelation: </h6>
              </div>
              <ul>
                <li>Remaining amount will be refundable to user. </li>
              </ul>
              <table
                className="table"
                data-aos="fade-up"
                data-aos-duration="1500"
              >
                <thead>
                  <tr>
                    <th scope="col">Cancelation</th>
                    <th scope="col">Deduction</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th scope="row">Within 20 minutes of booking</th>
                    <td>no deduction</td>
                  </tr>
                  <tr>
                    <th scope="row">4+ hour prior selected time</th>
                    <td>5% of amount submitted</td>
                  </tr>
                  <tr>
                    <th scope="row">3 hour prior selected time</th>
                    <td>10% of amount submitted</td>
                  </tr>
                  <tr>
                    <th scope="row">2 hour prior selected time</th>
                    <td>20% of amount submitted</td>
                  </tr>
                  <tr>
                    <th scope="row">1 hour prior selected time</th>
                    <td>30% of amount submitted</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="row text-center mt-3">
              <h3>Merchant</h3>
            </div>
            <hr />
            <div className="row" data-aos="fade-up" data-aos-duration="1500">
              <h6 className="py-3">Successful Visit:</h6>
              <div>
                <ul>
                  <li>
                    5% will be retained by App. Remaining amount will be added
                    to ground owner's wallet
                  </li>
                </ul>
              </div>
              <div className="row">
                <h3 className="py-4">
                  Booking Cancelation amount distribution:
                </h3>
                <table className="table">
                  <thead>
                    <tr>
                      <th scope="col">House</th>
                      <th scope="col">Deduction</th>
                      <th scope="col">Ground Owner</th>
                      <th scope="col">Maidan</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <th scope="row">1 hour</th>
                      <td>30% of amount deducted </td>
                      <td>21%</td>
                      <td>9%</td>
                    </tr>
                    <tr>
                      <th scope="row">2 hour</th>
                      <td>20% of amount deducted</td>
                      <td>14%</td>
                      <td>6%</td>
                    </tr>
                    <tr>
                      <th scope="row">3 hour</th>
                      <td>10% of amount deducted</td>
                      <td>7%</td>
                      <td>3%</td>
                    </tr>
                    <tr>
                      <th scope="row">4 hour and More</th>
                      <td>5% of amount deducted</td>
                      <td>3.5%</td>
                      <td>1.5%</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div
              className="row py-5"
              data-aos="fade-up"
              data-aos-duration="1500"
            >
              <h3>Payment:</h3>
              <p>
                Prices for services offered by Meidan and those provided by
                facility operators
              </p>
              <p>
                You agree to pay the applicable fees as shown on the Meidan Site
                and/or Application and are in control of any payments or fees
                associated with your account if you make a reservation using the
                Meidan Site and/or Application.
              </p>
              <p>
                Prices may occasionally change on the Meidan website and/or
                application. Meidan will never automatically add extra fees to a
                booking's pricing; instead, it will only do so for services that
                the User has consciously and freely chosen.
              </p>
              <p>
                Meidan reserves the right to deduct a portion of the cash as a
                commission for the provided service in the event that a booked
                activity is cancelled.
              </p>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

export default Term;
