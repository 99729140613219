import React, { Component } from "react";
import { Link, NavLink } from "react-router-dom";

class Footer extends Component {
  render() {
    return (
      <div>
        {/* footer */}
        <section id="footer">
          <div className="container ">
            <footer className="py-5">
              <div className="row contactUsSection">
                <div className="col-12 col-lg-4 mb-3 ">
                  <h5 className="white">About Us</h5>
                  <ul className="nav flex-column white">
                    <p className="nav-link p-0 text-muted">
                      Consider us the Pakistan’s largest indoor and outdoor
                      digital sports club where you may make reservations on
                      demand from any location at no additional charges.
                    </p>
                  </ul>
                  <a href="https://www.instagram.com/meidanpk/" target="_blank">
                    <img src="./img/icon/instagram-white.svg" />
                  </a>
                  <a
                    href="https://www.linkedin.com/company/meidan-technologies-private-limited/"
                    target="_blank"
                  >
                    <img src="./img/icon/linkedin-white.svg" />
                  </a>
                  <a href="https://www.facebook.com/meidanpk" target="_blank">
                    <img src="./img/icon/facebook-white.svg" />
                  </a>
                  <a href="https://twitter.com/meidanpk" target="_blank">
                    <img src="./img/icon/twitter-white.svg" />
                  </a>
                  <a href="https://www.tiktok.com/@meidanpk" target="_blank">
                    <img
                      style={{ maxWidth: "25px", marginLeft: "8px" }}
                      src="./img/Banner/tiktok.png"
                    />
                  </a>
                  <a
                    href="https://www.youtube.com/@meidanpakistan"
                    target="_blank"
                  >
                    <img
                      style={{ marginLeft: "20px" }}
                      src="./img/Banner/youtube.png"
                    />
                  </a>
                </div>
                <div className="col-12 col-lg-2 mb-3 ">
                  <h5 className="white">Useful Links</h5>
                  <ul className="nav flex-column">
                    <li className="nav-item">
                      <Link
                        to={"/how-playground-booking-works"}
                        mode="dark"
                        className="nav-link p-0 text-muted mb-2"
                      >
                        How It Works{" "}
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link
                        to={"/about-us/sports-ground"}
                        mode="dark"
                        className="nav-link p-0 text-muted mb-2"
                      >
                        About Us{" "}
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link
                        to={"/terms-and-conditions"}
                        mode="dark"
                        className="nav-link p-0 text-muted mb-2"
                      >
                        Terms & Condition
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link
                        to={"/privacy-policy"}
                        mode="dark"
                        className="nav-link p-0 text-muted mb-2"
                      >
                        Privacy Policy
                      </Link>
                    </li>
                  </ul>
                </div>

                <div className="col-12 col-lg-4 mb-3">
                  <form>
                    <h5 className="white">Contact Us</h5>
                    <ul className="nav flex-column white">
                      <li className="nav-item mb-2">
                        <a href="#" className="nav-link p-0 text-muted">
                          328L Johar town, Lahore, Pakistan{" "}
                        </a>
                      </li>
                      <li className="nav-item mb-2">
                        <a href="#" className="nav-link p-0 text-muted">
                          contact@meidan.pk
                        </a>
                      </li>
                      <li className="nav-item mb-2">
                        <a href="#" className="nav-link p-0 text-muted">
                          04237870328
                        </a>
                      </li>
                    </ul>
                  </form>
                </div>
              </div>
              <hr className="white" />
              <div className="row text-center">
                <div className="col">
                  <p className="white" style={{ fontSize: "12px" }}>
                    © 2023 MEIDAN TECHNOLOGIES (PRIVATE) LIMITED ALL RIGHTS
                    RESERVED.
                  </p>
                </div>
              </div>
            </footer>
          </div>
        </section>
      </div>
    );
  }
}

export default Footer;
