import VanueBanner from "../Layout/VanueBanner";
import DocumentMeta from "react-document-meta";

export default function BookVenue() {
  const currentURL = window.location.href;
  const meta = {
    title: "Become a Sports Venue Booking Partner",
    description:
      "Meidan helps sports players find & book facilities in the Pakistan. Hire facilities for 19 different sports.",
    canonical: currentURL,
    meta: {
      charset: "utf-8",
      name: {
        keywords:
          "Lahore sports facilities, Sports grounds in Garhi shahu, Sports grounds in Johar town, Sports grounds in DHA, Sports grounds on Canal Road, Sports grounds in Walton, Sports grounds in LDA, Sports grounds in Township, Sports grounds in Model Town, Sports grounds in Gulberg, Sports grounds in China Scheme, Sports grounds in Shad Bagh, Sports grounds in Kalma Chawk",
      },
    },
  };
  return (
    <>
      <DocumentMeta {...meta}>
        <VanueBanner />
        <div className="container-fluid">
          <div className="row pt-5">
            <div className="col-lg-8 offset-lg-2">
              <div className="row pt-5">
                <div className="col-12">
                  <iframe
                    src="https://docs.google.com/forms/d/e/1FAIpQLSeRgy5TkRWCMt7fZAl1_nLiji1qNZ2ytF0WTaeLsHORpRMhLg/viewform?embedded=true"
                    width="100%"
                    // Set the width as per your requirement
                    height="900"
                    // Set the height as per your requirement
                    frameBorder="0"
                    marginHeight="0"
                    marginWidth="0"
                    title="Google Form"
                  >
                    Loading...
                  </iframe>
                </div>
              </div>
            </div>
          </div>
        </div>
      </DocumentMeta>
    </>
  );
}
