export default function HowItWorkBanner() {
  return (
    <>
      <section className="d-flex justify-content-center align-items-center HowBanner Banner">
        <div className="" data-aos="fade-up" data-aos-duration="1500">
          <h2>How it Works</h2>
          <h6>Sports - You See This Name, You Think Meidan.</h6>
        </div>
      </section>
    </>
  );
}
