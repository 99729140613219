import React, { Component } from "react";
import AboutBanner from "./Layout/AboutBanner";
import WhoWeAre from "./Layout/WhoWeAre";
import DocumentMeta from "react-document-meta";

class AboutUs extends Component {
  render() {
    const currentURL = window.location.href;
    const meta = {
      title: "Sports Ground Booking in Lahore | Cricket Ground Booking",
      description:
        "Miedan is best for sports ground booking in Lahore. Moreover, Cricket Ground Booking is also available. It ensures your 100% tension-free booking.",
      canonical: currentURL,
      meta: {
        charset: "utf-8",
        name: {
          keywords:
            "Sports ground booking in Lahore, cricket ground booking, Find playgrounds, Book playgrounds, snooker clubs, women's sports complex, video games, outdoor and indoor games for adults, sports ground booking, sports grounds",
        },
      },
    };
    return (
      <DocumentMeta {...meta}>
        <div>
          <AboutBanner />
          {/* about us */}
          <section id="AboutUs">
            <div className="container">
              <div className="container pt-5 pb-5">
                <div className="row">
                  <div className="col-lg-12">
                    <h1
                      style={{ fontSize: "40px" }}
                      className="text-center pb-4"
                    >
                      Sports Ground Booking in Lahore
                    </h1>
                    <p className="text-center">
                      Meidan is your greatest friend when it comes to sports
                      ground booking in Lahore. Meidan makes sports ground
                      booking in Lahore simple, providing a stress-free
                      experience for all sports lovers. The user only has to
                      download the mobile APP and choose the location they like
                      or want to play, select the time slot they desire, pay,
                      and boom your booking is 100% done. Meidan's user-friendly
                      portal allows you to easily browse among many
                      possibilities for sports ground booking in Lahore,
                      ensuring you find the perfect location for your favorite
                      events.
                    </p>
                    <p className="text-center">
                      You can browse several possibilities for sports ground
                      booking in Lahore on Meidan. You can search for the places
                      near you or search for your favorite place name. choose
                      the time and pay by any medium (bank account or jazz
                      cash). There is no need to be concerned about hassles or
                      doubts because Meidan has made the entire process easy for
                      your needs. So, if you want to ensure a hassle-free
                      experience for your sports ground booking in Lahore,
                      Meidan is the platform to use.
                    </p>
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-12">
                    <h2
                      style={{ fontSize: "40px" }}
                      className="text-center pb-4"
                    >
                      Cricket Ground Booking in Lahore
                    </h2>
                    <p className="text-center">
                      The procedure of hiring a cricket ground at Meidan is as
                      easy as it gets. You can quickly guarantee your booking
                      for a game, assuring a 100% tension-free booking
                      experience. Meidan's cricket ground booking service
                      welcomes everyone, whether you're a seasoned player or a
                      beginner, to experience the excitement of the game without
                      any hassles. So, if you're ready to take a well-equipped
                      ground and unleash your cricketing potential, Meidan's
                      cricket ground booking is your ticket to a stress-free and
                      joyful cricketing experience.
                    </p>
                    <p className="text-center">
                      And, guess what? If cricket is your game, Meidan's cricket
                      ground booking option has you all. With Meidan's simple
                      technique, booking a cricket ground is simple. You may
                      choose the cricket ground that best meets your needs,
                      check time, and book! Your cricket ground booking in
                      Lahore has been completed. Meidan eliminates any worry and
                      guarantees a completely stress-free booking experience for
                      sports lovers. It is making it the top choice for anyone
                      wishing for a cricket ground booking in Lahore. So, if you
                      want a hassle-free booking experience, Meidan is the way
                      to go for all of your bookings in Lahore!
                    </p>
                  </div>
                </div>
              </div>
              <div className="row mt-3">
                <div
                  className="col-lg-6 order-2 order-md-1"
                  style={{ alignSelf: "center" }}
                  data-aos="fade-up"
                  data-aos-duration="1500"
                >
                  <h3 style={{ fontSize: "3rem", fontWeight: "700" }}>
                    Our Mission:
                  </h3>
                  <p>
                    We couldn't believe there wasn't a single location where
                    athletes could identify, get in touch with, and book
                    playgrounds in Lahore sports fields. We started by
                    conducting research, gaining support, and then developing a
                    platform that displayed every sporting facility in Pakistan
                    and was free and simple for anybody to use.
                  </p>
                  <p>
                    We are aware that maintaining a work-life balance in an
                    urban environment may be challenging, and our services
                    eliminate the stress of looking for locations and coworkers.
                  </p>
                  <p>
                    Our goal is to increase facility usage by making it simpler
                    for everyone to participate in sports by searching
                    “playgrounds in Lahore”, “nearest snooker clubs”, “sports
                    complex near me”, “video game shops”, and “outdoor and
                    indoor game for adults and kids. Meidan plays a crucial role
                    in bringing people together; sharing experiences and
                    improving wellbeing are its defining characteristics.
                  </p>
                </div>
                <div
                  className="col-lg-6 order-1 order-md-2"
                  style={{ alignSelf: "center", textAlign: "center" }}
                >
                  <img
                    src="../img/about/Group 177.png"
                    alt=""
                    style={{ width: "100%" }}
                    data-aos="fade-up"
                    data-aos-duration="1500"
                  />
                </div>
              </div>
            </div>
          </section>
          <WhoWeAre />
          <section id="ceo" className="mt-5 ">
            <div className="container mt-5 mb-5">
              <div className="row">
                <div
                  className="col-md-4 ceo-img"
                  data-aos="fade-up"
                  data-aos-duration="1500"
                >
                  <img src="../img/about/hamza.jpeg" alt="" />
                </div>
                <div
                  className="col-md-8 ceo-text"
                  data-aos="fade-up"
                  data-aos-duration="1500"
                >
                  <div className="content-ceo">
                    <h2 className="py-1">Director’s Vision:</h2>
                    {/* <h5>Welcome message:</h5> */}
                    <blockquote className="quote-card">
                      <p>
                        My visionary ambition to introduce "Meidan," the
                        first-ever sports ground booking brand in Pakistan, is
                        an indication of a forward-thinking approach and
                        commitment to revolutionizing sports in the country.
                        With this innovative concept, I aim to create a dynamic
                        space where people of all ages and backgrounds can come
                        together to engage in their favorite sports and athletic
                        activities. Meidan is not just a facility; it represents
                        a vision of promoting a healthier, more active lifestyle
                        among Pakistanis and nurturing sporting talent. By
                        bringing this innovative concept to life, I aspire to
                        fill a significant gap in the local sports
                        infrastructure and provide a platform that will
                        encourage both unprofessional and professional athletes
                        to pursue their passions and dreams. This visionary
                        initiative has the potential to leave a lasting legacy
                        in Pakistan's sporting history.
                      </p>
                    </blockquote>
                    <h6>Hamza Yaseen</h6>
                    <p>Director</p>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </DocumentMeta>
    );
  }
}

export default AboutUs;
