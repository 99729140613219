import React, { useEffect, useState } from "react";
import axios from "axios";
import VanueBanner from "./Layout/VanueBanner";
import DocumentMeta from "react-document-meta";
import { Link } from "react-router-dom";

export default function AvanueList() {
  const [groundData, setGroundData] = useState([]);
  const [searchData, setSearchData] = useState({
    search: "",
  });

  const currentURL = window.location.href;

  const meta = {
    title: "Find Your Ground in Lahore | Sports Partner Meidan",
    description:
      "Find your ground with the help of Meidan. It helps sports players find and book their favorite sports facilities in Lahore.",
    canonical: currentURL,
    meta: {
      charset: "utf-8",
      name: {
        keywords:
          "Find your ground in Lahore, Sports Partner, Book Sports Venues, Sports facilities, Sports grounds in Garhi shahu, Sports grounds in Johar town, Sports grounds in DHA, Sports grounds on Canal Road, Sports grounds in Walton, Sports grounds in LDA, Sports grounds in Township, Sports grounds in Model Town, Sports grounds in Gulberg, Sports grounds in China Scheme, Sports grounds in Shad Bagh, Sports grounds in Kalma Chawk",
      },
    },
  };

  useEffect(() => {
    getSponsorGround();
  }, []);

  const inputSearch = (e) => {
    setSearchData({ ...searchData, [e.target.name]: e.target.value });
    getListingGround();
  };

  const getSponsorGround = async () => {
    try {
      const sponserResponse = await axios.get(
        "https://api.meidan.pk/v1/ground-profiles/list/sponsor/"
      );
      const listingresponse = await axios.get(
        "https://api.meidan.pk/v1/ground-profiles/list/top-rated/?page=1"
      );
      let array = [...sponserResponse.data.data, ...listingresponse.data.data];
      const uniqueArray = array.filter(
        (item, index, self) => index === self.findIndex((t) => t.id === item.id)
      );

      setGroundData(uniqueArray);
    } catch (error) {
      console.log(error);
    }
  };

  const getListingGround = async (value) => {
    try {
      const searchAny = await axios.get(
        `https://api.meidan.pk/v1/searches/?page=1&q=${searchData.search}`
      );
      setGroundData(searchAny.data.data);
    } catch (error) {}
  };

  return (
    <>
      <DocumentMeta {...meta}>
        <VanueBanner />
        <div className="container pt-5 pb-3">
          <div className="row">
            <div className="col-lg-12">
              <h1 style={{ fontSize: "40px" }} className="text-center pb-4">
                Find Your Ground in Lahore:
              </h1>
              <p className="text-center">
                Are you looking for the perfect place to play your favorite
                sport in Lahore?
              </p>
              <p className="text-center">
                Look no further and find your ground with the help of Meidan.
                Meidan is your go-to site to book sports grounds. It also makes
                it easy for you to find, book, and play on time without time
                collapse. Meidan has made it easy, simple, and tension-free to
                find your ground.
              </p>
              <p className="text-center">
                Whether you're into cricket, football, or any other sport,
                Meidan is your sports booking friend. Find your ground with
                Meidan's user-friendly interface. It allows you to explore and
                book sports facilities that suit your needs. No more searching
                endlessly—Meidan smooths the "find your ground" experience and
                makes it easy for sports lovers across Lahore. So, if you're
                eager to step onto the perfect playing grounds, discover and
                book it effortlessly with Meidan. Find your ground with Meidan
                and enjoy your favorite sports like never before!
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12">
              <h1 style={{ fontSize: "40px" }} className="text-center pb-4">
                Sports Partner Meidan:
              </h1>
              <p className="text-center">
                Look no further in Lahore for your ideal sports partner—Meidan
                has you covered! Meidan is your greatest sports companion,
                making it simple to find, book, and play at your favorite
                playgrounds. Sports fans in Lahore depend entirely on Meidan to
                improve their experience of playing in their favorite
                playgrounds.
              </p>
              <p className="text-center">
                Meidan is more than simply a platform; it is your dependable
                sports partner that removes the pain of finding and booking
                playgrounds. Meidan makes it easy to go from looking for the
                right site to joining in your favorite sports. Users respect
                Meidan as a sports partner because it provides a simple and
                efficient experience, allowing them to focus on what they like
                most—playing sports. So, if you're in Lahore and looking for a
                trustworthy sports partner to help you find, book, and play in
                your favorite playgrounds, Meidan is the name you can trust for
                a simple and fun sports experience.
              </p>
            </div>
          </div>
        </div>
        <div className="container-fluid">
          <div className="row pt-5">
            <div className="col-lg-8 offset-lg-2">
              <div class="row height d-flex justify-content-center align-items-center">
                <div class="col-md-8">
                  <div class="search d-flex justify-content-center">
                    <div className="w-100">
                      <img
                        src="./img/loupe.png"
                        alt=""
                        className="fa fa-search"
                      />
                      <input
                        type="text"
                        class="form-control"
                        placeholder="Search Venues"
                        onChange={inputSearch}
                        value={searchData.search}
                        name="search"
                      />
                    </div>
                    <div>
                      <button class="btn m-0 btn-primary">Search</button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row pt-5">
                {groundData.length === 0 ? (
                  <>
                    {Array.from({ length: 6 }).map((_, index) => (
                      <div className="col-lg-4 mb-4">
                        <div className="shimmer">
                          <div>
                            <label className="line"></label>
                            <h5
                              style={{ minHeight: "200px" }}
                              className="line"
                            ></h5>
                            <div className="">
                              <div className="p-3">
                                <div className="d-flex align-items-center">
                                  <p className="line"></p>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="shimmer-anim"></div>
                        </div>
                      </div>
                    ))}
                  </>
                ) : (
                  groundData.map((item) => (
                    <div className="col-lg-4" key={item.id}>
                      {/* <Link
                        to={{
                          pathname: "/venues-detail",
                          state: { groundDetail: item },
                        }}
                      > */}
                      <div>
                        <div className="product">
                          <figure className="product-media">
                            <a href>
                              <img src={item.background} alt="Category" />
                            </a>
                            <div className="product-label-group">
                              <label className="product-label label-new ">
                                Hot
                              </label>
                            </div>
                          </figure>
                          <div className="d-flex aligns-items-center justify-content-between">
                            <div className="p-3">
                              <h5 className="text-black ">{item.name}</h5>
                              <p>
                                {item?.address?.city}, {item?.address?.state},{" "}
                                {item?.address?.country}
                              </p>
                              <p>{item.games}</p>
                            </div>
                            <div className="p-3">
                              <div className="d-flex align-items-center">
                                <img src="../img/star.svg" alt="" />
                                <p className="text-black">
                                  {item.total_reviews}
                                </p>
                              </div>
                            </div>
                          </div>
                          <a
                            className="book-now position"
                            href="https://play.google.com/store/apps/details?id=com.meidanuserapp"
                            target="_blank"
                          >
                            Book Now
                          </a>
                        </div>
                      </div>
                      {/* </Link> */}
                    </div>
                  ))
                )}
              </div>
            </div>
          </div>
        </div>
      </DocumentMeta>
    </>
  );
}
