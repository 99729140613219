import React, { Component } from "react";
import MerchantBanner from "./Layout/MerchantBanner";
import OwnerBenefit from "./Layout/OwnerBenefit";
import DocumentMeta from "react-document-meta";

class Merchant extends Component {
  render() {
    const currentURL = window.location.href;

    const meta = {
      title: "Meidan Merchant Account",
      description:
        "Meidan Merchant Account is open for Ground owners to provide you with business. It is to register their grounds to double their income from now.",
      canonical: currentURL,
      meta: {
        charset: "utf-8",
        name: {
          keywords:
            "Merchant account, Register grounds, Sports Ground Booking, Sports ground listing, Registration form for merchant",
        },
      },
    };
    return (
      <DocumentMeta {...meta}>
        <div>
          <MerchantBanner />
          <div className="container pt-2 pb-5">
            <div className="row pt-5">
              <div className="col-lg-12">
                <h1 style={{ fontSize: "40px" }} className="text-center pb-4">
                  Merchant Account Meidan:
                </h1>
                <p className="text-center">
                  Excellent news for ground owners! Meidan provides an exclusive
                  opportunity for ground owners to grow their businesses and
                  triple their profits with its Merchant Account. Ground owners
                  are able to register their grounds with the Meidan Merchant
                  Account. This opens up a world of opportunities for them. It
                  helps you to attract new customers to increase income. Ground
                  owners may simply manage their bookings using the Merchant
                  Account, It creates a smooth experience for both owners and
                  sports lovers.
                </p>
                <p className="text-center">
                  Registering with the Meidan Merchant Account not only benefits
                  ground owners but also people looking to book grounds. The
                  Merchant Account's simplicity and efficiency allow ground
                  owners to focus on what they do best: providing exceptional
                  sports facilities. So, if you're a ground owner wishing to
                  boost your income and develop your business, Meidan's Merchant
                  Account is the key to unlocking opportunities and attracting
                  customers to your grounds.
                </p>
              </div>
            </div>
          </div>
          <OwnerBenefit />
          <section>
            <div className="container " style={{ overflowX: " hidden" }}>
              <div className="row">
                <h3 className="text-center py-5">
                  Steps to join merchant Meidan App
                </h3>
              </div>{" "}
              <div className="row py-2 ">
                <div className="col-12 col-md-5 col-lg-5 s-1 ">
                  <img
                    src="../img/become-a-merchant/step1.png"
                    alt=""
                    style={{ width: "100%" }}
                    data-aos="fade-right"
                    data-aos-duration="2000"
                  />
                </div>
                <div
                  className="col-12 col-md-6 col-lg-6 mer-1"
                  data-aos="fade-left"
                  data-aos-duration="2000"
                >
                  <h4>Registration as a user:</h4>
                  <span>STEP 1</span>
                  <p>
                    First of all, like any common user needs to register Meidan
                    account.
                  </p>
                </div>
              </div>
              {/* 2 */}
              <div className="row py-2 g-5">
                <div
                  className="col-12 col-md-6 col-lg-6 mer-1 order-2 order-md-1"
                  data-aos="fade-right"
                  data-aos-duration="2000"
                >
                  <h4>Settings to become Merchant:</h4>
                  <span>STEP 2</span>
                  <p>
                    The user has to Go to Account settings and then click "to
                    become a merchant" option. <br />
                    There will be a KYC form. Fill in all the requirements and
                    submit it to become a merchant on Meidan.
                  </p>
                </div>
                <div className="col-12 col-md-5 col-lg-5 s-2  order-1 order-md-2">
                  <img
                    src="../img/become-a-merchant/step2.png"
                    alt=""
                    style={{ width: "100%" }}
                    data-aos="fade-left"
                    data-aos-duration="2000"
                  />
                </div>
              </div>
              {/* 3 */}
              <div className="row py-2 g-5">
                <div className="col-12 col-md-5 col-lg-5 s-3 ">
                  <img
                    src="../img/become-a-merchant/step3.5.png"
                    alt=""
                    style={{ width: "100%" }}
                    data-aos="fade-right"
                    data-aos-duration="2000"
                  />
                </div>
                <div
                  className="col-12 col-md-6 col-lg-6 mer-1"
                  data-aos="fade-left"
                  data-aos-duration="2000"
                >
                  <h4> Verification Email:</h4>
                  <span>STEP 3</span>
                  <p>
                    Within 24 hours KYC form is analyzed and reviewed to check
                    accuracy of filled information. Meidan accepts the form
                    after satisfying all the mentioned details.
                    <br /> Rejection/acceptance email will be delivered to the
                    applicant.
                  </p>
                </div>
              </div>
              {/* 4 */}
              <div className="row py-2 g-5">
                <div
                  className="col-12 col-md-6 col-lg-6 mer-1  order-2 order-md-1"
                  data-aos="fade-right"
                  data-aos-duration="2000"
                >
                  <h4>Manual Merchant App:</h4>
                  <span>STEP 4</span>
                  <p>
                    Meidan team hand over merchant app manually. That mean team
                    itself will deliver you App to use merchant account
                    accordingly.
                  </p>
                </div>
                <div
                  className="col-12 col-md-5 col-lg-5 s-4  order-1 order-md-2"
                  data-aos="fade-left"
                  data-aos-duration="2000"
                >
                  <img
                    src="../img/become-a-merchant/step4.png"
                    alt=""
                    style={{ width: "100%" }}
                  />
                </div>
              </div>
              {/* 5 */}
              <div className="row py-2 g-5">
                <div
                  className="col-12 col-md-5 col-lg-5 s-1 "
                  data-aos="fade-right"
                  data-aos-duration="2000"
                >
                  <img
                    src="../img/become-a-merchant/step5.png"
                    alt=""
                    style={{ width: "100%" }}
                  />
                </div>
                <div
                  className="col-12 col-md-6 col-lg-6 mer-1"
                  data-aos="fade-left"
                  data-aos-duration="2000"
                >
                  <h4>Download Merchant App: </h4>
                  <span>STEP 5</span>
                  <p>
                    It is also possible to download merchant app from Meidan
                    website. Merchant can download it from website. Manual
                    installation is recommended in case of any mismanagement.
                  </p>
                </div>
              </div>
              {/* 6 */}
              <div className="row py-2 g-5">
                <div
                  className="col-12 col-md-6 col-lg-6 mer-1 order-2 order-md-1"
                  data-aos="fade-right"
                  data-aos-duration="2000"
                >
                  <h4>Log in Merchant App </h4>
                  <span>STEP 6</span>
                  <p>Merchant needs to log in after attaining manual App.</p>
                </div>
                <div
                  className="col-12 col-md-5 col-lg-5 s-2  order-1 order-md-2"
                  data-aos="fade-left"
                  data-aos-duration="2000"
                >
                  <img
                    src="../img/become-a-merchant/step6.png"
                    alt=""
                    style={{ width: "100%" }}
                  />
                </div>
              </div>
              {/* 7 */}
              <div className="row py-2 g-5">
                <div
                  className="col-12 col-md-5 col-lg-5 s-3 "
                  data-aos="fade-right"
                  data-aos-duration="2000"
                >
                  <img
                    src="../img/become-a-merchant/step7.png"
                    alt=""
                    style={{ width: "100%" }}
                  />
                </div>
                <div
                  className="col-12 col-md-6 col-lg-6 mer-1"
                  data-aos="fade-left"
                  data-aos-duration="2000"
                >
                  <h4>Manage merchant App:</h4>
                  <span>STEP 7</span>
                  <p>
                    Merchants can manage App with attractive images of grounds,
                    players and tournaments. Publish "Time slots" and "charges"
                    schedules.
                  </p>
                </div>
              </div>
            </div>
          </section>
        </div>
      </DocumentMeta>
    );
  }
}

export default Merchant;
