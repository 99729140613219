import { TypeAnimation } from "react-type-animation";

export default function HomeBanner() {
  return (
    <>
      <div className="position-relative home-banner">
        <video
          playsinline="playsinline"
          autoplay="autoplay"
          muted="muted"
          loop="loop"
          class=""
        >
          <source src="./img/backvideo.mp4" type="video/mp4" />
        </video>
        <div className="container h-100">
          <div className="row h-100 d-flex align-items-center">
            {/* <div class="col-md-6 d-md-none">
              <div class="banner-img-wrap text-center">
                <img
                  style={{ maxWidth: "300px" }}
                  src="./img/Download/meidan mockup .png"
                />
              </div>
            </div> */}
            <div class="col-md-12 text-center">
              <div class="banner-caption-wrap">
                <h5 className="">
                  <TypeAnimation
                    sequence={[
                      // Same substring at the start will only be typed out once, initially
                      "Meidan Is Your Ultimate Sports Partner.",
                      100, // wait 1s before replacing "Mice" with "Hamsters"
                    ]}
                    wrapper="span"
                    speed={200}
                    style={{
                      fontSize: "60px",
                      color: "#fff",
                      fontWeight: "700",
                      display: "inline-block",
                    }}
                    repeat={Infinity}
                  />
                  {/* <span style={{ fontWeight: "700", fontSize: "60px" }}>
                    Find
                  </span> */}
                  {/* <span
                    style={{
                      fontWeight: "700",
                      fontSize: "60px",
                      color: "#fff",
                    }}
                  >
                    Your
                  </span>{" "}
                  <span style={{ fontWeight: "700", fontSize: "60px" }}>
                    Venues
                  </span> */}
                </h5>
                <p
                  style={{
                    color: "#0aa70a",
                    fontWeight: "bold",
                    fontSize: "25px",
                  }}
                >
                  Booking Sports Grounds Was Never Easier Before
                </p>
              </div>
            </div>
            {/* <div class="col-md-6 d-none d-md-block">
              <div class="banner-img-wrap text-center">
                <img src="./img/Download/meidan mockup .png" />
              </div>
            </div> */}
          </div>
        </div>
      </div>

      {/* <div className="position-relative test">
        <div className="header-search">
          <h5 className="">
            <span style={{ fontWeight: "700", fontSize: "35px" }}>Find</span>{" "}
            <span
              style={{ fontWeight: "700", fontSize: "35px", color: "black" }}
            >
              Your
            </span>{" "}
            <span style={{ fontWeight: "700", fontSize: "35px" }}>Venues</span>
          </h5>
          <p style={{ color: "black" }}>
            Book sports pitches and courts in the Pakistan.
          </p>
          <Select options={options} className="pb-3" />
          <Link to="/venues" className="book-now">
            Find Venue
          </Link>
        </div>
      </div> */}
    </>
  );
}
