export default function UserBenefit() {
  return (
    <>
      <div id="benefits" className="container mt-5">
        <div className="row">
          <h3 className="text-center mb-5">USER BENEFITS</h3>
        </div>
        <div className="row">
          <div className="col-12 col-md-6 col-lg-4">
            <div
              className="cards-1"
              data-aos="fade-up"
              data-aos-duration="2000"
            >
              <div className="">
                <img
                  src="../img/howItWork/7.svg"
                  alt=""
                  style={{ width: "80px", marginBottom: "10px" }}
                />
              </div>
              <div className="">
                <h6 className="font-weight-medium">
                  Median is regardless of time and place
                </h6>
                <p className="fw-light text-muted">
                  User is benefited from using App from anywhere to everywhere.
                  There is no time and place restriction. Even App can work out
                  of country. Only internet is required to select desired ground
                  to play.
                </p>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-6 col-lg-4">
            <div
              className="cards-1"
              data-aos="fade-up"
              data-aos-duration="2000"
            >
              <div className="">
                <img
                  src="../img/howItWork/2.svg"
                  alt=""
                  style={{ width: "80px", marginBottom: "10px" }}
                />
              </div>
              <div className="">
                <h6 className="font-weight-medium">
                  Avoid long calls barrier{" "}
                </h6>
                <p className="fw-light text-muted">
                  Avoid long calls barrier If we look back, players had to
                  search playgrounds in Lahore then make calls to find out
                  charges and times. Most people didn't get a favorable
                  response. Moreover, long calls take much time, energy and
                  money. Meidan app has ended all the barriers.
                </p>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-6 col-lg-4">
            <div
              className="cards-1"
              data-aos="fade-up"
              data-aos-duration="2000"
            >
              <div className="">
                <img
                  src="../img/howItWork/3.svg"
                  alt=""
                  style={{ width: "80px", marginBottom: "10px" }}
                />
              </div>
              <div className="">
                <h6 className="font-weight-medium">
                  Avoid misinformation regarding time and money
                </h6>
                <p className="fw-light text-muted">
                  This is the most improved benefit of Meidan user App as it
                  replaces misinformation with accurate and authentic data
                  regarding ground specs, time slots and charges. User will be
                  informed accurately in any term.
                </p>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-6 col-lg-4">
            <div
              className="cards-1"
              data-aos="fade-up"
              data-aos-duration="2000"
            >
              <div className="">
                <img
                  src="../img/howItWork/4.svg"
                  alt=""
                  style={{ width: "80px", marginBottom: "10px" }}
                />
              </div>
              <div className="">
                <h6 className="font-weight-medium">Registered App by PTA</h6>
                <p className="fw-light text-muted">
                  As people want to be guided right and legal path, Meidan has
                  registered itself to PTA (Pakistan Telecommunication
                  Authority) after clearing all the terms and conditions.
                </p>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-6 col-lg-4">
            <div
              className="cards-1"
              data-aos="fade-up"
              data-aos-duration="2000"
            >
              <div className="">
                <img
                  src="../img/howItWork/5.svg"
                  alt=""
                  style={{ width: "80px", marginBottom: "10px" }}
                />
              </div>
              <div className="">
                <h6 className="font-weight-medium">Economical </h6>
                <p className="fw-light text-muted">
                  All types of grounds are available. Some of the grounds charge
                  less and some more. User may have variety to choose ground
                  according to pocket.
                </p>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-6 col-lg-4">
            <div
              className="cards-1"
              data-aos="fade-up"
              data-aos-duration="2000"
            >
              <div className="">
                <img
                  src="../img/howItWork/1.svg"
                  alt=""
                  style={{ width: "80px", marginBottom: "10px" }}
                />
              </div>
              <div className="">
                <h6 className="font-weight-medium">
                  No Google search required
                </h6>
                <p className="fw-light text-muted">
                  User doesn’t need to search Google to find nearby or desired
                  grounds to go. Meidan App gives all the grounds to play. Only
                  user has to find, book and play.
                </p>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-6 col-lg-4">
            <div
              className="cards-1"
              data-aos="fade-up"
              data-aos-duration="2000"
            >
              <div className="">
                <img
                  src="../img/howItWork/6.svg"
                  alt=""
                  style={{ width: "80px", marginBottom: "10px" }}
                />
              </div>
              <div className="">
                <h6 className="font-weight-medium">Multiple ground options</h6>
                <p className="fw-light text-muted">
                  As human nature wants multiple options to choose from, Meidan
                  gives the right direction to choose from unlimited options.
                  Only user needs to switch on phone location so App search bar
                  could work properly.
                </p>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-6 col-lg-4">
            <div
              className="cards-1"
              data-aos="fade-up"
              data-aos-duration="2000"
            >
              <div className="">
                <img
                  src="../img/howItWork/8.svg"
                  alt=""
                  style={{ width: "80px", marginBottom: "10px" }}
                />
              </div>
              <div className="">
                <h6 className="font-weight-medium">
                  Builds regular profiles for professional players
                </h6>
                <p className="fw-light text-muted">
                  In Pakistan, young players are very fond of playing at
                  national level. It requires a lot of effort and professional
                  carrier profile. As Meidan app makes you get rid to old
                  keeping journals, user is benefited from professional profiles
                  to select at national level and make country proud.
                </p>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-6 col-lg-4">
            <div
              className="cards-1"
              data-aos="fade-up"
              data-aos-duration="2000"
            >
              <div className="">
                <img
                  src="../img/howItWork/9.svg"
                  alt=""
                  style={{ width: "80px", marginBottom: "10px" }}
                />
              </div>
              <div className="">
                <h6 className="font-weight-medium">Easy to use</h6>
                <p className="fw-light text-muted">
                  Meidan App is easy as any other simple applications. There is
                  no technicality for the user. Even a person who is not from
                  technical background can use it effectively.
                </p>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-6 col-lg-4">
            <div
              className="cards-1"
              data-aos="fade-up"
              data-aos-duration="2000"
            >
              <div className="">
                <img
                  src="../img/howItWork/10.svg"
                  alt=""
                  style={{ width: "80px", marginBottom: "10px" }}
                />
              </div>
              <div className="">
                <h6 className="font-weight-medium">
                  User may play with friends, family or both.
                </h6>
                <p className="fw-light text-muted">
                  Friends and Family time is the most exciting and enjoying time
                  for each of us. At Sundays or Saturdays family and friends may
                  have plans to go and have fun. To meet this enjoying time,
                  Meidan gives you all the available grounds with all the
                  facilities you may need.
                </p>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-6 col-lg-4">
            <div
              className="cards-1"
              data-aos="fade-up"
              data-aos-duration="2000"
            >
              <div className="">
                <img
                  src="../img/howItWork/11.svg"
                  alt=""
                  style={{ width: "80px", marginBottom: "10px" }}
                />
              </div>
              <div className="">
                <h6 className="font-weight-medium">
                  Chat with friends to reserve and contribute to play
                </h6>
                <p className="fw-light text-muted">
                  The amazing benefit is allowing the chat with friends to
                  decide ground according to pocket. So, friends and family have
                  some fun together.
                </p>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-6 col-lg-4">
            <div
              className="cards-1"
              data-aos="fade-up"
              data-aos-duration="2000"
            >
              <div className="">
                <img
                  src="../img/howItWork/12.svg"
                  alt=""
                  style={{ width: "80px", marginBottom: "10px" }}
                />
              </div>
              <div className="">
                <h6 className="font-weight-medium">
                  Environmental information such as weather report
                </h6>
                <p className="fw-light text-muted">
                  As play time is fun time. It will be boring for players if
                  they find rainy or windy weather. Weather report is given so
                  user would satisfy with playing with no uncertain whether
                  occurrence.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12 btn">
            <a
              href="https://play.google.com/store/apps/details?id=com.meidanuserapp"
              target="_blank"
              id="loadMore"
            >
              <button> Load more </button>
            </a>
          </div>
        </div>
      </div>
    </>
  );
}
