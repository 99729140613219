import { SET_GROUND_DATA } from "../types/grounds"



const initialState  = {
    groundData : []
}

const groundReducer = (state = initialState, action) => {
    switch (SET_GROUND_DATA) {
        case SET_GROUND_DATA:
            console.log("DATA ",action.payload)
            return state
        default: return state
    }
}

export default groundReducer