export default function KnowUs() {
  return (
    <>
      <section className="knowus">
        <div className="container">
          <div className="row justify-content-md-center text-center ">
            <div className="col blog-text mb-5">
              <h2>Know Us</h2>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6 border-right">
              <div class="block text-center">
                <img src="../img/Banner/works.png" alt="" />
                <h5 className="my-3">How it works</h5>
                <p class="feature-content">
                  Sign up and explore sports venues and opponent teams in your
                  city.
                </p>
              </div>
            </div>
            <div className="col-md-6">
              <div class="block text-center">
                <img src="../img/Banner/facility.png" alt="How It Works" />
                <h5 className="my-3">LIST YOUR SPORTS FACILITY</h5>
                <p class="feature-content">
                  Sign up as facility Manager, promote your sports venue. It’s
                  Easy its free.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
