import React from "react";

const Booking = () => {
  return (
    <div className="container pt-5 pb-5">
      <div className="row">
        <div className="col-lg-12">
          <h1 style={{ fontSize: "40px" }} className="pb-3">
            Ground booking in Lahore
          </h1>
          <p>
            Ground booking in Lahore has become a piece of cake, with "Meidan."
            Whether you're a sports lover or just looking for some fun, it
            confirms your enjoyment of playing indoor and outdoor games. With
            Meidan's user-friendly platform, you can easily play your favorite
            sports in your favorite playground without any hassle.
          </p>
          <p>
            The best part is that it allows you to favorite ground bookings in
            Lahore quickly and at economical rates. Just imagine the power to
            schedule your indoor and outdoor games in seconds through Meidan and
            enjoy ground booking in Lahore. No need to worry about complex
            processes – It simplifies the whole experience, making ground
            booking in Lahore available to everyone.
          </p>
          <p>
            Meidan presents an easy service of ground booking in Lahore to let
            you play Indoor and outdoor games. Whether you're organizing a
            friendly match with friends or planning a community sports event,
            Meidan has got your back. Take the lead on the quick and economical
            options of ground booking in Lahore. Say goodbye to booking
            headaches and hello to Meidan's platform for all your needs for
            ground booking in Lahore.
          </p>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-12">
          <h2 style={{ fontSize: "40px" }} className="pb-3">
            Ground booking for Indoor and Outdoor Games
          </h2>
          <p>
            Meidan, the go-to sports ground booking app in Lahore, is a
            game-changer for sports lovers looking for both indoor and outdoor
            gaming experiences. With Meidan, user’s possibilities for indoor and
            outdoor games are endless It offers a diverse range of options for
            everyone's sporting preferences.
          </p>
          <p>
            Whether you like to play indoor games like badminton or wish for the
            adventure of outdoor sports like cricket, Meidan helps you in every
            type of sport. The app itself is a platform to allows users to book
            indoor and outdoor gaming spaces at their convenience. It also
            ensures that you can enjoy your favorite activities in any weather.
          </p>
          <p>
            Meidan’s commitment is to promote a healthy lifestyle by providing
            easy access to facilities for both indoor and outdoor games. Whether
            you’re planning an indoor match with friends or an outdoor sport
            under the sky, Meidan simplifies the process of securing the perfect
            space for your chosen indoor and outdoor games.
          </p>
          <p>
            The user-friendly interface makes the experience of finding and
            booking the ideal venue for your preferred indoor and outdoor games
            enjoyable and stress-free. With Meidan, the world of indoor and
            outdoor sports is at your fingertips, encouraging individuals to
            embrace the joy of both types of games with utmost convenience.
          </p>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-12">
          <h3 style={{ fontSize: "40px" }} className="pb-3">
            Find, Book, and Play
          </h3>
          <p>
            Find, book, and play in your favorite playground has become as easy
            as pie. Meidan has revolutionized the way we book grounds
            previously. Booking ground with Meidan is not only simple but also
            budget-friendly, ensuring that you can reserve your favorite space
            without any hassle. The user-friendly interface makes the booking
            ground experience enjoyable.
          </p>
          <p>
            Don't stress about complicated procedures (long calls,
            unavailability of ground, desired time slot, or high prices) Meidan
            has got your ground booking needs protected, making it a piece of
            cake to organize your desired game session. Whether you're planning
            a sports event or just want to have some fun with friends, Meidan is
            your go-to platform for hassle-free and affordable services for
            ground booking.
          </p>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-12">
          <h3 style={{ fontSize: "40px" }} className="pb-3">
            Why Meidan?
          </h3>
          <p>
            Certainly! Here are some key points about why people in Lahore
            should download the Meidan Sports Ground Booking App:
          </p>
          <ul className="list-inline why-meidan-list">
            <li>Easy Access to Sports Facilities</li>
            <li>Time-Saving</li>
            <li>Real-Time Availability</li>
            <li>User-Friendly Interface</li>
            <li>Secure and Transparent Transactions</li>
            <li>Notification Alerts</li>
            <li>Variety of Sports Options</li>
            <li>Community Building</li>
            <li>Feedback and Reviews</li>
            <li>Promotions and Discounts</li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Booking;
