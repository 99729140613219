import React, { Component } from "react";
import HowItWorkBanner from "./Layout/HowItWorkBanner";
import UserBenefit from "./Layout/UserBenefit";
import DocumentMeta from "react-document-meta";

class HowitWorks extends Component {
  // componentDidMount() {
  //   this.loadMore();
  // }

  // loadMore = () => {
  //   $(document).ready(function () {
  //     $(".content").slice(0, 6).show();
  //     $("#loadMore").on("click", function (e) {
  //       e.preventDefault();
  //       $(".content:hidden").slice(0, 6).slideDown();
  //       if ($(".content:hidden").length == 0) {
  //         $("#loadMore").text("").addClass("noContent");
  //       }
  //     });
  //   });
  // };

  render() {
    const currentURL = window.location.href;
    const meta = {
      title: "Book Playgrounds in Lahore | Meidan",
      description:
        "See how a user can book playgrounds in Lahore. Just enter your location and hit the search button. You’ll have plenty of playgrounds.",
      canonical: currentURL,
      meta: {
        charset: "utf-8",
        name: {
          keywords:
            "Book playgrounds in Lahore, Playgrounds in Lahore, Sports Complex, Largest network of sporting facilities, Sports ground booking, Find playgrounds in Lahore, Book playgrounds in Lahore, Playgrounds near me, nearest snooker clubs, sports complex near me, video game shop, outdoor and indoor games, sports grounds",
        },
      },
    };

    return (
      <DocumentMeta {...meta}>
        <div>
          <HowItWorkBanner />
          <section id="how">
            <div className="container" style={{ overflowX: "hidden" }}>
              <div className="row">
                <div className="col-lg-12">
                  <h1 style={{ fontSize: "40px" }} className="text-center pb-4">
                    Book Playgrounds in Lahore:
                  </h1>
                  <p className="text-center">
                    Book playgrounds in Lahore have become a piece of cake. All
                    you have to do to "book playgrounds in Lahore" is enter your
                    location and hit the search button. It's that simple! A big
                    list of playgrounds in Lahore will appear before you in a
                    moment, ready for you to find and book without any second
                    thought.
                  </p>
                  <p className="text-center">
                    There's no need to be concerned about where to find the best
                    location for your favorite activities. It is a simple
                    process to book playgrounds in Lahore. The procedure is
                    smooth and efficient, whether you're planning an energetic
                    game with friends or a workout individually. So, don't be
                    shy—just enter your location, press the search button, and
                    you'll be taken to a world of options to find and
                    book playgrounds in Lahore.
                  </p>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-6" id="howToImageContainer">
                  <img
                    data-aos="fade-right"
                    data-aos-duration="2000"
                    className="how-img "
                    style={{ width: "100%" }}
                    src="./img/How/Group 178.png"
                    alt=""
                  />
                </div>
                <div
                  className="col-lg-6 how-text"
                  data-aos="fade-left"
                  data-aos-duration="2000"
                >
                  <h2>Find Playgrounds</h2>
                  <p>
                    1. What do you want to play ?<br />
                    2. Where do you want to play ?<br />
                    3. When do you want to Play ? <br />
                    Discover Lahore’s largest network of playgrounds. You only
                    need to enter your location and sport before pressing the
                    search button. That's all and You will get a number of
                    suitable results according to your desired list to book
                    playgrounds in Lahore.
                  </p>
                </div>
                {/* 2st book */}
                <div className="row mt-5">
                  <div
                    className="col-lg-6 how-text order-2 order-lg-1"
                    data-aos="fade-right"
                    data-aos-duration="2000"
                  >
                    <h2>Book Playgrounds</h2>
                    <p>
                      Once you've found the ideal field, you’ve to book
                      playgrounds in Lahore. Use the search button to get in
                      touch with the venue and make an online reservation. To
                      make organizing sports easier, we collaborate with the top
                      sports facilities in Lahore.
                    </p>
                  </div>
                  <div
                    className="col-lg-6 order-1 order-lg-2"
                    id="howToImageContainer"
                  >
                    <img
                      className="how-img "
                      src="./img/How/Group 179.png"
                      alt=""
                      data-aos="fade-left"
                      data-aos-duration="2000"
                      style={{ width: "100%" }}
                    />
                  </div>
                </div>
              </div>
              {/* 3st find */}
              <div className="row mt-5">
                <div className="col-lg-6" id="howToImageContainer">
                  <img
                    className="how-img "
                    src="./img/How/Mask group.png"
                    alt=""
                    data-aos="fade-right"
                    data-aos-duration="2000"
                    style={{ width: "100%" }}
                  />
                </div>
                <div
                  className="col-lg-6 how-text"
                  data-aos="fade-left"
                  data-aos-duration="2000"
                >
                  <h2>You can Play</h2>
                  <p>
                    {" "}
                    You're the hero; you easily discovered and reserved a
                    gorgeous pitch or court, and it's time to start playing. The
                    stage is prepared for your momentous game, in which hopes
                    might be made or broken. Suffering is just momentary, while
                    triumph is eternal.
                  </p>
                </div>
              </div>
              <div className="row">
                <div className="col-12 justify-content-center  text-center">
                  <a
                    className="fill  "
                    href="https://play.google.com/store/apps/details?id=com.meidanuserapp"
                    target="_blank"
                  >
                    <button>Download app Now</button>
                  </a>
                </div>
              </div>
              {/* modal */}
              <div
                className="modal fade"
                id="exampleModal"
                tabIndex={-1}
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
              >
                <div className="modal-dialog">
                  <div className="modal-content">
                    <div className="modal-header">
                      <h5 className="modal-title" id="exampleModalLabel">
                        Comming Soon
                      </h5>
                      <button
                        type="button"
                        className="btn-close"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                      />
                    </div>
                    <div className="modal-body">
                      Meidan application will be available on App Store &amp;
                      Google Play Store Soon.
                    </div>
                    <div className="modal-footer">
                      <button
                        type="button"
                        className="btn btn-danger"
                        data-bs-dismiss="modal"
                      >
                        Close
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="modal fade"
                id="exampleModal"
                tabIndex={-1}
                role="dialog"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
              >
                <div className="modal-dialog" role="document">
                  <div className="modal-content">
                    <div className="modal-header">
                      <h5 className="modal-title" id="exampleModalLabel">
                        Modal title
                      </h5>
                      <button
                        type="button"
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                      >
                        <span aria-hidden="true">×</span>
                      </button>
                    </div>
                    <div className="modal-body">...</div>
                    <div className="modal-footer">
                      <button
                        type="button"
                        className="btn btn-secondary"
                        data-dismiss="modal"
                      >
                        Close
                      </button>
                      <button type="button" className="btn btn-primary">
                        Save changes
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          {/* benefits */}
          <UserBenefit />
          <section>
            <div className="container">
              <div className="row text-center justify-content-center pt-5">
                <h2>MEIDAN USER APP FEATURES</h2>
                <img
                  src="../img/about/Group 325.png"
                  alt=""
                  style={{ width: "70%" }}
                  data-aos="fade-up"
                  data-aos-duration="2000"
                />
              </div>
            </div>
          </section>
        </div>
      </DocumentMeta>
    );
  }
}

export default HowitWorks;
